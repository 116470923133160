import { CommonOption } from '@customTypes/calculation'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import Select, { OnChangeValue, SingleValue } from 'react-select'
import { selectStyles } from './styleConfig'

type StatusSelectorProps = {
  options: Array<CommonOption>
  onParentChange: (value: string) => void
  value: string
}

const StatusSelector = ({ options, onParentChange, value }: StatusSelectorProps) => {
  const onChange = useCallback(
    (value: SingleValue<CommonOption>) => {
      const currentValue = value?.value ? String(value?.value) : ''

      onParentChange(currentValue)
    },
    [onParentChange]
  )

  const valueNumber = useMemo(() => (value ? Number(value) : ''), [value])

  return (
    <div className="form-control-select">
      <Select
        isSearchable={false}
        styles={selectStyles}
        isMulti={false}
        options={options}
        isClearable
        placeholder="Select status..."
        value={options.find(option => option.value === valueNumber)}
        onChange={onChange}
      />
    </div>
  )
}

export default StatusSelector
