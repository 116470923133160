import { useCallback, useEffect, useMemo, useState } from 'react'
import FormInput from './FormInput'
import { useForm } from 'react-hook-form'
import FormPhoneNumberInput from './FormPhoneNumberInput'
import { yupResolver } from '@hookform/resolvers/yup'
import txYupScheme from '@utils/yupSchemes/txYupSchemes'
import FormFileInput from './FormFileInput'
import { File } from 'buffer'
import Icon from '@components/Icon'
import transactionUtils from '@utils/transaction'
import FormSelector from './CustomSelector/CustomSelector'
import { selectStyles } from './CustomSelector/styleConfig'
import { useAppSelector } from '@hooks/reduxHooks'
import calculationUtils from '@utils/calculation'
import CommonSenderFormField from './CommonSenderFormField'
import CommonBeneficiaryFormField from './CommonBeneficiaryFormField'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import CommonCreditPartyFormField from './CommonCreditPartyFormField'
import commonUtils from '@utils/common'
import {
  BeneficiaryForNewTxDetailsForm,
  CreditPartyIdentifierForNewTxDetailsForm,
  NewTxDetailsFormType,
  SenderForNewTxDetailsForm,
} from '@customTypes/transaction'

type NewTxDetailsFormProps = {
  onParentSubmit: (dataForm: NewTxDetailsFormType) => void
  txDetails: NewTxDetailsFormType | null
  isShownForm: boolean
  payerId?: number
}

const NewTxDetailsForm = ({
  onParentSubmit,
  txDetails,
  payerId,
  isShownForm,
}: NewTxDetailsFormProps) => {
  const { payers } = useAppSelector(state => state.calculation)

  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const formFieldsConfig = useMemo(
    () => (payerId ? calculationUtils.getNewTxDetailsFromFieldsConfig(payers, payerId) : null),
    [payerId, payers]
  )

  const { handleSubmit, formState, control, setValue, reset } = useForm<NewTxDetailsFormType>({
    resolver: yupResolver(txYupScheme.newTxDetailsFormScheme(formFieldsConfig)),
  })

  const setFormValue = useCallback(() => {
    setValue('purposeOfRemittance', txDetails?.purposeOfRemittance || '')

    setValue('creditPartyIdentifier.msisdn', txDetails?.creditPartyIdentifier.msisdn)
    setValue('creditPartyIdentifier.iban', txDetails?.creditPartyIdentifier.iban)
    setValue(
      'creditPartyIdentifier.swift_bic_code',
      txDetails?.creditPartyIdentifier.swift_bic_code
    )
    setValue(
      'creditPartyIdentifier.bank_account_number',
      txDetails?.creditPartyIdentifier.bank_account_number
    )

    //sender
    setValue('sender.firstname', txDetails?.sender.firstname || '')
    setValue('sender.lastname', txDetails?.sender.lastname || '')
    setValue('sender.id_number', txDetails?.sender.id_number || '')
    setValue('sender.code', txDetails?.sender.code)
    setValue('sender.email', txDetails?.sender.email)
    setValue('sender.date_of_birth', txDetails?.sender.date_of_birth)
    setValue(
      'sender.country_of_birth_iso_code',
      txDetails?.sender.country_of_birth_iso_code || 'ZWE'
    )
    setValue('sender.address', txDetails?.sender.address)
    setValue('sender.city', txDetails?.sender.city)
    setValue('sender.country_iso_code', txDetails?.sender.country_iso_code)
    setValue('sender.address', txDetails?.sender.address)

    setValue('file', txDetails?.file)

    //benficiary
    setValue('beneficiary.firstname', txDetails?.beneficiary.firstname || '')
    setValue('beneficiary.lastname', txDetails?.beneficiary.lastname || '')
    setValue('beneficiary.code', txDetails?.beneficiary.code)
    setValue('beneficiary.email', txDetails?.beneficiary.email)
    setValue('beneficiary.date_of_birth', txDetails?.beneficiary.date_of_birth)
    setValue(
      'beneficiary.country_of_birth_iso_code',
      txDetails?.beneficiary.country_of_birth_iso_code
    )
    setValue('beneficiary.address', txDetails?.sender.address)
    setValue('beneficiary.city', txDetails?.beneficiary.city)
    setValue('beneficiary.country_iso_code', txDetails?.beneficiary.country_iso_code)
    setValue('beneficiary.address', txDetails?.beneficiary.address)

    if (!txDetails?.creditPartyIdentifier.msisdn) {
      setValue('beneficiary.msisdn', txDetails?.beneficiary.msisdn)
    }
  }, [
    setValue,
    txDetails?.beneficiary.address,
    txDetails?.beneficiary.city,
    txDetails?.beneficiary.code,
    txDetails?.beneficiary.country_iso_code,
    txDetails?.beneficiary.country_of_birth_iso_code,
    txDetails?.beneficiary.date_of_birth,
    txDetails?.beneficiary.email,
    txDetails?.beneficiary.firstname,
    txDetails?.beneficiary.lastname,
    txDetails?.beneficiary.msisdn,
    txDetails?.creditPartyIdentifier.bank_account_number,
    txDetails?.creditPartyIdentifier.iban,
    txDetails?.creditPartyIdentifier.msisdn,
    txDetails?.creditPartyIdentifier.swift_bic_code,
    txDetails?.file,
    txDetails?.purposeOfRemittance,
    txDetails?.sender.address,
    txDetails?.sender.city,
    txDetails?.sender.code,
    txDetails?.sender.country_iso_code,
    txDetails?.sender.country_of_birth_iso_code,
    txDetails?.sender.date_of_birth,
    txDetails?.sender.email,
    txDetails?.sender.firstname,
    txDetails?.sender.id_number,
    txDetails?.sender.lastname,
  ])

  useEffect(() => {
    // if (txDetails) {
    setFormValue()
    // }
  }, [setFormValue])

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState])

  const onSubmit = (dataForm: NewTxDetailsFormType) => {
    const sender = commonUtils.trimObjectFields(
      pick(dataForm.sender, formFieldsConfig?.sender || [])
    ) as SenderForNewTxDetailsForm
    const beneficiary = commonUtils.trimObjectFields(
      pick(dataForm.beneficiary, formFieldsConfig?.beneficiary || [])
    ) as BeneficiaryForNewTxDetailsForm
    const creditPartyIdentifier = commonUtils.trimObjectFields(
      pick(dataForm.creditPartyIdentifier, formFieldsConfig?.creditPartyIdentifier || [])
    ) as CreditPartyIdentifierForNewTxDetailsForm
    const id_number = sender.id_number.replace(/[^a-zA-Z0-9]/g, '')

    const modifiedDataForm = {
      ...dataForm,
      sender: { ...sender, id_number },
      beneficiary,
      creditPartyIdentifier,
    }

    // console.log(modifiedDataForm)

    onParentSubmit(modifiedDataForm)
  }

  const onClickReset = () => {
    reset()
  }

  if (!isShownForm) {
    return null
  }

  return (
    <form
      id="tx-details-form"
      className="box is-white d-flex flex-col gap-24"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <h4 className="mb-16">Sender</h4>

        <div className="fieldset">
          <FormInput<NewTxDetailsFormType>
            name="sender.firstname"
            control={control}
            placeholder="First name"
            label="First name"
            type="text"
            maxLength={64}
          />
          <FormInput<NewTxDetailsFormType>
            name="sender.lastname"
            control={control}
            placeholder="Last name"
            label="Last name"
            type="text"
            maxLength={64}
          />
        </div>

        <div className="fieldset" style={{ flexWrap: 'wrap' }}>
          <FormInput<NewTxDetailsFormType>
            name="sender.id_number"
            control={control}
            placeholder="Id number"
            label="Id number"
            type="text"
            maxLength={32}
          />
          {formFieldsConfig &&
            formFieldsConfig?.sender.map(field => (
              <CommonSenderFormField key={field} name={field} control={control} />
            ))}
        </div>
      </div>

      <div className="d-flex flex-col gap-8">
        <FormFileInput<NewTxDetailsFormType> name="file" control={control} />

        <div className="divider">
          <hr />
          <div className="divider-icon">
            <Icon name="arrows-down" size={24} color="#D5DBE2"></Icon>
          </div>
          <hr />
        </div>

        <div>
          <h4 className="mb-16">Recipient</h4>
          <div className="fieldset">
            <FormInput<NewTxDetailsFormType>
              name="beneficiary.firstname"
              control={control}
              placeholder="First name"
              label="First name"
              type="text"
              maxLength={64}
            />
            <FormInput<NewTxDetailsFormType>
              name="beneficiary.lastname"
              control={control}
              placeholder="Last name"
              label="Last name"
              type="text"
              maxLength={64}
            />
          </div>
          <div className="fieldset" style={{ flexWrap: 'wrap' }}>
            {formFieldsConfig &&
              formFieldsConfig?.beneficiary.map(field => (
                <CommonBeneficiaryFormField key={field} name={field} control={control} />
              ))}
          </div>
        </div>
      </div>

      {formFieldsConfig?.beneficiary.includes('msisdn') &&
        !formFieldsConfig?.creditPartyIdentifier.includes('msisdn') && (
          <FormPhoneNumberInput<NewTxDetailsFormType>
            name="beneficiary.msisdn"
            control={control}
            placeholder="Mobile number"
            label="Mobile number1"
          />
        )}

      {formFieldsConfig &&
        formFieldsConfig?.creditPartyIdentifier.map(field => (
          <CommonCreditPartyFormField key={field} name={field} control={control} />
        ))}

      <div className="fieldset">
        <FormSelector<NewTxDetailsFormType>
          options={transactionUtils.purposeOfRemittanceOptions}
          name="purposeOfRemittance"
          control={control}
          styles={selectStyles}
          labelName="Purpose of remittance"
        />
      </div>

      <hr />

      <div className="button-group">
        <button type="button" className="btn btn-tertiary" onClick={onClickReset}>
          Clear Form
        </button>
        <button
          form="tx-details-form"
          type="submit"
          className="btn btn-primary"
          disabled={isDisabledButton}
        >
          Continue
        </button>
      </div>
    </form>
  )
}

export default NewTxDetailsForm
