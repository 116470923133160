import { calculationActionCreators } from '@actionCreators/calculationActionCreators'
import { transactionActionCreators } from '@actionCreators/transactionActionCreators'
import { CalculationFormDataType } from '@components/calculation/CalculationForm'
import EndScreen from '@components/newTx/endScreen/EndScreen'
import FormScreen from '@components/newTx/formScreen/FormScreen'
import ReviewScreen from '@components/newTx/reviewScreen/ReviewScreen'
import { NewTxDetailsFormType } from '@customTypes/transaction'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { useCallback, useEffect, useMemo, useState } from 'react'

export type NewTxScreen = 'form' | 'review' | 'end'

const NewTx = () => {
  const dispatch = useAppDispatch()

  const { calculationInfo } = useAppSelector(state => state.calculation)

  const [newTxScreen, setNewTxScreen] = useState<NewTxScreen>('form')
  const [txDetails, setTxDetails] = useState<NewTxDetailsFormType | null>(null)
  const [calcDetails, setCalcDetails] = useState<CalculationFormDataType | null>(null)

  // console.log(txDetails)

  const isShownNewTxForm = useMemo(
    () =>
      Boolean(calculationInfo) &&
      !calculationInfo?.errors?.length &&
      Boolean(calcDetails?.payers?.value),
    [calcDetails?.payers?.value, calculationInfo]
  )

  useEffect(() => {
    dispatch(calculationActionCreators.makeInitialCalculation())
  }, [dispatch])

  const onSubmitFrom = useCallback((dataForm: NewTxDetailsFormType) => {
    setTxDetails(dataForm)

    setNewTxScreen('review')
  }, [])

  const onSubmitCalcForm = useCallback((dataForm: CalculationFormDataType) => {
    setCalcDetails(dataForm)
  }, [])

  const onCreateTx = useCallback(() => {
    if (txDetails) {
      const body = { ...txDetails, ...{ quotationId: calculationInfo?.quotationId || 0 } }


      const formData = new FormData()
      formData.append('quotationId', String(body.quotationId))
      formData.append('creditPartyIdentifier', JSON.stringify(body.creditPartyIdentifier))
      formData.append('sender', JSON.stringify(body.sender))
      formData.append('beneficiary', JSON.stringify(body.beneficiary))
      formData.append('purposeOfRemittance', body.purposeOfRemittance)
      formData.append('file', body.file as File)

      dispatch(transactionActionCreators.createTransaction(formData))
        .unwrap()
        .then(() => {
          setNewTxScreen('end')
        })
    }
  }, [calculationInfo?.quotationId, dispatch, txDetails])

  const renderView = useMemo(() => {
    switch (newTxScreen) {
      case 'form':
        return (
          <FormScreen
            onSubmitCalcForm={onSubmitCalcForm}
            onParentSubmit={onSubmitFrom}
            txDetails={txDetails}
            payerId={calcDetails?.payers?.value}
            isShownForm={isShownNewTxForm}
          />
        )
      case 'review':
        return (
          <ReviewScreen
            setNewTxScreen={setNewTxScreen}
            txDetails={txDetails}
            calcDetails={calcDetails}
            onParentConfirm={onCreateTx}
          />
        )
      case 'end':
        return <EndScreen />
    }
  }, [
    calcDetails,
    isShownNewTxForm,
    newTxScreen,
    onCreateTx,
    onSubmitCalcForm,
    onSubmitFrom,
    txDetails,
  ])

  return renderView
}

export default NewTx
