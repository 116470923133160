import { useCallback, useEffect, useState } from 'react'
import { RouterProvider } from 'react-router-dom'
import router from '@routes/routerConfig'
import { auth } from '@fire-base/mainFirebase.js'
import ErrorModal from '@components/calculation/ui/ErrorModal'
import { useAppDispatch } from '@hooks/reduxHooks'
import { User } from '@firebase/auth'
import authActionCreators from '@actionCreators/authActionCreators'

import '@fontsource/manrope'
import '@fontsource/manrope/700.css'
import '@fontsource/manrope/500.css'
import '@fontsource/poppins'
import '@fontsource/poppins/900.css'
import '@fontsource/poppins/800.css'
import '@fontsource/poppins/700.css'
import '@fontsource/poppins/600.css'

function App() {
  const dispatch = useAppDispatch()

  const authorizeUser = useCallback(
    async (user: User) => {
      const userFirebaseAccessToken = await user.getIdToken(false)
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const body = {
        providerId: user.providerData[0].providerId,
        accessToken: userFirebaseAccessToken,
        timezone: userTimeZone,
      }

      // console.log(userFirebaseAccessToken)

      dispatch(authActionCreators.authorizeUser(body))
    },
    [dispatch]
  )

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      // console.log(user)
      if (user) {
        authorizeUser(user)
      } else {
      }
    })

    return () => unsubscribe()
  }, [authorizeUser, dispatch])
  return (
    <>
      <RouterProvider router={router} />
      <ErrorModal />
    </>
  )
}

export default App
