import CalculationForm, { CalculationFormDataType } from '@components/calculation/CalculationForm'
import NewTxDetailsForm from './NewTxDetailsForm'
import { NewTxDetailsFormType } from '@customTypes/transaction'

type FormScreenProps = {
  onParentSubmit: (dataForm: NewTxDetailsFormType) => void
  txDetails: NewTxDetailsFormType | null
  onSubmitCalcForm: (dataForm: CalculationFormDataType) => void
  payerId?: number
  isShownForm: boolean
}

const FormScreen = ({
  onParentSubmit,
  txDetails,
  onSubmitCalcForm,
  payerId,
  isShownForm,
}: FormScreenProps) => {
  return (
    <>
      <section className="form-section">
        <h3 className="t-center mb-40">New Transaction (Fill in the details)</h3>
        <div className="container container-lg">
          <div className="background-container d-flex justify-content-center">
            <div className="forms-container box is-transparent d-flex gap-24">
              <div className="calculation-form-wrapper">
                <CalculationForm isShownSubmitBtn={false} onParentSubmit={onSubmitCalcForm} />
              </div>
              <div className="tx-details-form-wrapper">
                <NewTxDetailsForm
                  onParentSubmit={onParentSubmit}
                  txDetails={txDetails}
                  payerId={payerId}
                  isShownForm={isShownForm}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FormScreen
