import { CalculateMode, CountryOption } from '@customTypes/calculation'
import CalculationInput from './CalculationInput'
import CountrySelectorWithSearch from './CountySelector'

type InputWithCountriesProps = {
  label: string
  inputName: string
  inputValue: string
  selectorName: string
  selectorValue: CountryOption
  mode: CalculateMode
  onInputChange: (value: any, name: string, mode: CalculateMode) => void
  onSelectorChange: (value: any, name: string) => void
  options: Array<CountryOption>
  isLoading: boolean
}

const InputWithCountrySelector = ({
  label,
  inputName,
  inputValue,
  selectorName,
  selectorValue,
  onInputChange,
  onSelectorChange,
  options,
  mode,
  isLoading,
}: InputWithCountriesProps) => {
  return (
    <div className='input-wrapper'>
      <CalculationInput
        onParentChange={onInputChange}
        label={label}
        name={inputName}
        value={inputValue}
        mode={mode}
        isLoading={isLoading}
      />
      <div className='input-wrapper--country'>
        <CountrySelectorWithSearch
          selectorName={selectorName}
          selectorValue={selectorValue}
          options={options}
          onParentChange={onSelectorChange}
        />
      </div>
    </div>
  )
}

export default InputWithCountrySelector
