import { Action, UnknownAction, configureStore } from '@reduxjs/toolkit'
import authSlice from '@sliceActionCreators/authSlice'
import calculationSlice from '@sliceActionCreators/calculationSlice'
import globalErrorModalSlice from '@sliceActionCreators/globalErrorModalSlice'
import transactionSlice from '@sliceActionCreators/transactionSlice'
import userSlice from '@sliceActionCreators/userSlice'

const rootReducer = {
  auth: authSlice,
  user: userSlice,
  calculation: calculationSlice,
  transaction: transactionSlice,
  globalErrorModal: globalErrorModalSlice,
}

export const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>
// export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch
