
const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th id="id" scope="col">Id</th>
        <th id="date" scope="col">Date/Time</th>
        <th id="sender" scope="col">Sender</th>
        <th id="source-amount" scope="col">Source Amount</th>
        <th id="destination-amount" scope="col">Destination Amount</th>
        <th id="status" scope="col">Status</th>
      </tr>
    </thead>
  )
}

export default TableHeader
