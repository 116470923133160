import Icon from '@components/Icon'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'

type SearchInputProps = {
  value: string
  onParentChange: (value: string) => void
}

const SearchInput = ({ value, onParentChange }: SearchInputProps) => {
  const [inputValue, setInputValue] = useState('')

  useEffect(()=>{
    if(value) {
      setInputValue(value)
    }
    
  },[value])

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      setInputValue(value)

      onParentChange(value)
    },
    [onParentChange]
  )

  const onClear = useCallback(()=>{
    setInputValue('')

    onParentChange('')
  },[onParentChange])
  return (
    <div className="search-control">
      <input
        className="form-control"
        type="text"
        onChange={onChange}
        // value={typeof value === 'undefined' ? '' : value}
        value={inputValue}
      />
      {value ? (
        <Icon name="close" size={24} color="#506379" onClick={onClear}/>
      ) : (
        <Icon name="search" size={24} color="#506379" />
      )}
    </div>
  )
}

export default SearchInput
