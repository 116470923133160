import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import { Outlet, useLocation } from 'react-router-dom'

const Layout = () => {
  const location = useLocation()

  return (
    <>
      <main id="main" className='d-flex flex-col'>
        <Header />

        <Outlet />

        <Footer />
      </main>
    </>
  )
}

export default Layout
