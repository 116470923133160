import authActionCreators from "@actionCreators/authActionCreators"
import userActionCreators from "@actionCreators/userActionCreators"
import { User } from "@customTypes/user"
import { createSlice } from "@reduxjs/toolkit"
import { omit } from "lodash"

type UserSlice = {
  user: User | null
}

const initialState: UserSlice = {
  user: null
}


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // .addCase(authActionCreators.authorizeUser.fulfilled, (state, action) => {
      //   state.user = action.payload
      // })

      .addCase(userActionCreators.getProfile.fulfilled, (state, action) => {
        state.user = state.user?.name ? { ...state.user, ...omit(action.payload, ['name']) } : { ...state.user, ...action.payload }
      })

      .addCase(authActionCreators.signUp.fulfilled, (state, action) => {

        state.user = {
          ...state.user, ...{ name: `${action.meta.arg.firstName} ${action.meta.arg.lastName}` }
        }
      })


      .addCase(authActionCreators.logOut.fulfilled, () => initialState)
  }
})

export default userSlice.reducer