import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

const EndScreen = () => {
  const navigate = useNavigate()

  const onClickViewTxBtn = useCallback(() => {
    navigate('/transactions')
  }, [navigate])

  return (
    <section className="success-page flex-1">
      <div className="background-container h-100">
        <div className="place-center">
          <p className="success-page-title">All Sent</p>
          <button
            type="button"
            className="btn btn-primary confirm-button"
            onClick={onClickViewTxBtn}
          >
            View Transaction
          </button>
        </div>
      </div>
    </section>
  )
}

export default EndScreen
