import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import Icon from '@components/Icon'
import { Credentials, EmailFormType, LoginScreenState, SignUpArgsRequest } from '@customTypes/auth'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppSelector } from '@hooks/reduxHooks'
import { isEmpty } from 'lodash'
import { useForm } from 'react-hook-form'
import commonUtils from '@utils/common'
import FormInput from '@components/newTx/formScreen/FormInput'
import loginYupSchemes from '@utils/yupSchemes/loginYupSchemes'

type SignInFormProps = {
  onParentSubmit: (formData: EmailFormType) => void
  userData: SignUpArgsRequest
  setCurrentScreen: Dispatch<SetStateAction<LoginScreenState>>
}

const SignInForm = ({ onParentSubmit, setCurrentScreen, userData }: SignInFormProps) => {
  const signInViaEmailIsLoading = useAppSelector(
    state => state.auth.signInViaEmailAndPasswordLoadState.isLoading
  )

  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const { handleSubmit, control, formState, setValue } = useForm<Credentials>({
    resolver: yupResolver(loginYupSchemes.signInFormScheme),
  })

  const setFormValue = useCallback(() => {
    setValue('email', userData.email)
  }, [setValue, userData.email])

  useEffect(() => {
    setFormValue()
  }, [setFormValue])

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState])

  const onSubmit = (dataForm: Credentials) => {
    const trimmedDataForm = commonUtils.trimObjectFields(dataForm) as Credentials

    onParentSubmit(trimmedDataForm)
  }

  const onBack = useCallback(() => {
    setCurrentScreen('email')
  }, [setCurrentScreen])

  const onClickResetPassword = useCallback(() => {
    setCurrentScreen('reset-password')
  }, [setCurrentScreen])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="box d-flex flex-col gap-24 is-white">
        <div>
          <div className="d-flex align-items-center gap-8 mb-16">
            <button className="transparent-button" onClick={onBack}>
              <Icon name="arrow-left" size={20} />
            </button>
            <h4>Sign In</h4>
          </div>
          <FormInput
            name="email"
            control={control}
            placeholder="Enter email"
            type="string"
            label="Email"
            maxLength={64}
            autoComplete="on"
          />
        </div>
        <FormInput
          name="password"
          control={control}
          placeholder="Enter password"
          type="password"
          label="Password"
          maxLength={32}
        />
        <button
          className="btn btn-primary w-100"
          disabled={isDisabledButton || signInViaEmailIsLoading}
        >
          Sign In
        </button>
        <button className="btn btn-secondary w-100" onClick={onClickResetPassword}>
          Forgot password?
        </button>
      </form>
    </>
  )
}

export default SignInForm
