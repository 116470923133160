// import { EmailFormType, LoginScreenState } from '@customTypes/login'
import { useCallback, useMemo, useState } from 'react'
import EmailForm from './EmailForm'
import { Credentials, EmailFormType, LoginScreenState, SignUpArgsRequest } from '@customTypes/auth'
import SignInForm from './SignInForm'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import authActionCreators from '@actionCreators/authActionCreators'
import { omit } from 'lodash'
import SignUpForm from './SignUpForm'
import { useNavigate } from 'react-router-dom'
import ResetPasswordForm from './ResetPasswordForm'
import { BeatLoader } from 'react-spinners'

const LoginViaEmailAndPassword = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const signUpIsLoading = useAppSelector(state => state.auth.signUpLoadState.isLoading)
  const signInViaEmailIsLoading = useAppSelector(
    state => state.auth.signInViaEmailAndPasswordLoadState.isLoading
  )

  const [currentScreen, setCurrentScreen] = useState<LoginScreenState>('email')
  const [userData, setUserData] = useState<SignUpArgsRequest>({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  })

  const onSubmit = useCallback(
    (formData: EmailFormType | Credentials | SignUpArgsRequest) => {
      if (currentScreen === 'email') {
        setUserData(prevState => ({ ...prevState, ...{ email: formData.email } }))
        setCurrentScreen('sign-in')
      }

      if (currentScreen === 'sign-in') {
        setUserData(prevState => ({
          ...prevState,
          ...{ email: formData.email },
        }))
        const bodyRequest = omit(formData, ['firstName', 'lastName']) as Credentials

        dispatch(authActionCreators.signInViaEmailAndPassword(bodyRequest))
          .unwrap()
          .then(() => {
            navigate('/')
          })
      }

      if (currentScreen === 'sign-up') {
        setUserData(prevState => ({
          ...prevState,
          ...formData,
        }))
        const args = { ...(formData as SignUpArgsRequest) }

        dispatch(authActionCreators.signUp(args))
          .unwrap()
          .then(() => {
            navigate('/')
            // dispatch(authActionCreators.sendEmailVerificationAfterSignUp())
          })
      }

      if (currentScreen === 'reset-password') {
        setUserData(prevState => ({
          ...prevState,
          ...{ email: formData.email },
        }))

        dispatch(authActionCreators.sendEmailToResetPassword(formData.email))
          .unwrap()
          .then(() => {
            setCurrentScreen('sign-in')
          })
      }
    },
    [currentScreen, dispatch, navigate]
  )

  const renderView = useMemo(() => {
    switch (currentScreen) {
      case 'email':
        return (
          <EmailForm
            onParentSubmit={onSubmit}
            userData={userData}
            setCurrentScreen={setCurrentScreen}
          />
        )
      case 'sign-in':
        return (
          <SignInForm
            onParentSubmit={onSubmit}
            userData={userData}
            setCurrentScreen={setCurrentScreen}
          />
        )
      case 'sign-up':
        return (
          <SignUpForm
            onParentSubmit={onSubmit}
            userData={userData}
            setCurrentScreen={setCurrentScreen}
          />
        )
      case 'reset-password':
        return (
          <ResetPasswordForm
            onParentSubmit={onSubmit}
            userData={userData}
            setCurrentScreen={setCurrentScreen}
          />
        )
    }
  }, [currentScreen, onSubmit, userData])
  return (
    <>
      {(signUpIsLoading || signInViaEmailIsLoading) && (
        <div className="spinner spinner-fixed">
          <BeatLoader size={30} color="#3171d8" />
        </div>
      )}

      {renderView}

    </>
  )
}

export default LoginViaEmailAndPassword
