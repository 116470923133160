import { CreateTxRequestBody, GetTransactionListResponseBody, TxDetails } from "@customTypes/transaction"
import { anonymousRequest } from "./anonymousRequest"
import { authorizedRequest } from "@api/authorizeRequest"

const createTx = (body: FormData) => {
  const path = ['money-transfer', 'transactions-file']
  const contentType = 'multipart/form-data'

  return authorizedRequest<FormData, null>('v1', path, "POST", body, null, contentType)
}

const getTransactionList = (page: number, limit: number, queryParams?: Record<string, string>) => {
  const path = ['money-transfer', 'transactions']
  const query = {
    page,
    limit,
    ...queryParams,
  }


  return authorizedRequest<null, GetTransactionListResponseBody>('v1', path, "GET", null, query)
}

const getTransactionDetailsById = (id: string) => {
  const path = ['money-transfer', 'transactions', id]


  return authorizedRequest<null, TxDetails>('v1', path, "GET")
}

const getTransactionLogsById = (id: string) => {
  const path = ['money-transfer', 'transactions', id, 'logs']


  return authorizedRequest<null, any>('v1', path, "GET")
}


export const transactionServices = {
  createTx,
  getTransactionList,
  getTransactionDetailsById,
  getTransactionLogsById,
}