import { useCallback } from 'react'

type SocialMediaButtonProps = {
  id: 'google' | 'tikTok'
  svg: JSX.Element
  text: string
  onParentClick: (id: 'google' | 'tikTok') => void
}

const SocialMediaButton = ({ id, svg, text, onParentClick }: SocialMediaButtonProps) => {
  const onClick = useCallback(() => {
    onParentClick(id)
  }, [id, onParentClick])

  return (
    <button
      className="btn btn-secondary d-flex align-items-center justify-content-center gap-8 w-100"
      onClick={onClick}
    >
      {svg}
      <span className="h5">{text}</span>
    </button>
  )
}

export default SocialMediaButton
