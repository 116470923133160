import React, { useMemo } from 'react'
import { Control, FieldValues, Path, UseControllerProps } from 'react-hook-form'
import FormInput from './FormInput'
import FormDateInput from './FormDateInput'
import FormSelector from './CustomSelector/CustomSelector'
import transactionUtils from '@utils/transaction'
import { selectStyles } from './CustomSelector/styleConfig'
import FormPhoneNumberInput from './FormPhoneNumberInput'
import { NewTxDetailsFormType } from '@customTypes/transaction'


type CommonCreditPartyFormFieldProps = {
  name: string
  control: Control<NewTxDetailsFormType>
}

const CommonCreditPartyFormField = ({ name, control }: CommonCreditPartyFormFieldProps) => {
  const renderView = useMemo(() => {
    switch (name) {
      case 'msisdn': {
        return (
          <FormPhoneNumberInput<NewTxDetailsFormType>
          name="creditPartyIdentifier.msisdn"
          control={control}
          placeholder="Mobile number"
          label="Mobile number"
        />
        )
      }
      case 'iban': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="creditPartyIdentifier.iban"
            control={control}
            placeholder="IBAN"
            label="IBAN"
            type="text"
            maxLength={64}
          />
        )
      }
      case 'swift_bic_code': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="creditPartyIdentifier.swift_bic_code"
            control={control}
            placeholder="SWIFT/BIC code"
            label="SWIFT/BIC code"
            type="text"
            maxLength={32}
          />
        )
      }
      case 'bank_account_number': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="creditPartyIdentifier.bank_account_number"
            control={control}
            placeholder="Bank account number"
            label="Bank account number"
            type="text"
            maxLength={64}
          />
        )
      }
    
    }
  }, [control, name])
  return renderView
}

export default CommonCreditPartyFormField
