import { Dispatch, Fragment, SetStateAction, useCallback, useState } from 'react'
import { NewTxScreen } from '@pages/NewTx'
import { useAppSelector } from '@hooks/reduxHooks'
import ErrorModal from '@components/calculation/ui/ErrorModal'
import Icon from '@components/Icon'
import { CalculationFormDataType } from '@components/calculation/CalculationForm'
import {
  BeneficiaryForNewTxDetailsForm,
  CreditPartyIdentifierForNewTxDetailsForm,
  NewTxDetailsFormType,
  SenderForNewTxDetailsForm,
} from '@customTypes/transaction'
import transactionUtils from '@utils/transaction'
import omit from 'lodash/omit'
import { BeatLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

type ReviewScreenProps = {
  txDetails: NewTxDetailsFormType | null
  setNewTxScreen: Dispatch<SetStateAction<NewTxScreen>>
  calcDetails: CalculationFormDataType | null
  onParentConfirm: () => void
}

const ReviewScreen = ({
  txDetails,
  setNewTxScreen,
  calcDetails,
  onParentConfirm,
}: ReviewScreenProps) => {
  const navigate = useNavigate()
  const { calculationInfo } = useAppSelector(state => state.calculation)
  const { createTransactionLoadState } = useAppSelector(state => state.transaction)

  const onClickBack = useCallback(() => {
    setNewTxScreen('form')
  }, [setNewTxScreen])

  const onCancelClick = useCallback(() => {
    navigate('/transactions')
  }, [navigate])

  const onConfirm = useCallback(() => {
    onParentConfirm()
  }, [onParentConfirm])

  if (!txDetails) {
    return null
  }

  return (
    <>
      {createTransactionLoadState.isLoading && (
        <div className="spinner spinner-fixed">
          <BeatLoader size={30} color="#3171d8" />
        </div>
      )}
      <section className="form-section">
        <h3 className="t-center mb-40">Review your transaction detail</h3>
        <div className="container container-lg">
          <div className="background-container d-flex justify-content-center">
            <div className="review-container box is-transparent d-flex">
              <div className="review-dialog-wrapper">
                <div className="review-dialog box is-white d-flex flex-col gap-16 f-monrope-sm fw-500">
                  <div>
                    <div className="h5 mb-8">Transaction Details</div>
                    <hr />
                  </div>

                  <div>
                    <div>
                      <div className="form-row mb-8">
                        <div className="d-flex align-items-center gap-8">
                          Sent Amount <Icon name="arrow-left" size={16} color="#0C9B00" />
                        </div>
                      </div>
                      <div className="form-row mb-8">
                        <div className="h3">
                          <span style={{ color: '#0C9B00' }}>+&nbsp;</span>
                          <span>
                            {calculationInfo
                              ? `${calculationInfo.source.amount.toFixed(2)} ${
                                  calculationInfo.source.currency
                                }`
                              : '255 UAH'}
                          </span>
                        </div>
                        <div>Status</div>
                      </div>

                      <div className="form-row mb-8">
                        <div className="d-flex align-items-center gap-8">
                          Receive Amount <Icon name="arrow-right" size={16} color="#B91C1C" />
                        </div>
                        <div className="d-flex align-items-center gap-4 h5">
                          {/* <Icon name="ellipse" size={6} color="#F59E0B" /> In processing */}-
                        </div>
                      </div>
                      <div className="h3" style={{ color: '#B91C1C' }}>
                        <span>-&nbsp;</span>
                        {calculationInfo?.destination.amount.toFixed(2)}{' '}
                        {calculationInfo?.destination.currency}
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div className="d-flex justify-content-between">
                    <div>
                      <p className="mb-4">Time and Date</p>
                      {/* <p className="fw-700">10:54pm, 05/23/2024</p> */}
                      <p className="fw-700">-</p>
                    </div>
                    <div className="transaction-id">
                      <p className="mb-4">Transaction ID</p>
                      <p className="fw-700">xx-xxx-x</p>
                    </div>
                  </div>

                  <hr />

                  <div>
                    <dl className="definition-list gap-8">
                      <dt>Payout Type</dt>
                      <dd>{calcDetails?.services?.label}</dd>

                      <dt>Payout Method</dt>
                      <dd>{calcDetails?.payers?.label}</dd>

                      <dt>Payout Fee</dt>
                      <dd>
                        {calculationInfo
                          ? `${calculationInfo.fee.amount.toFixed(2)} ${
                              calculationInfo.fee.currency
                            }`
                          : '22.99 UAH'}
                      </dd>
                    </dl>
                  </div>

                  <hr />

                  <div className="mb-8">
                    <h4 className="mb-8">Sender</h4>
                    <hr />
                  </div>

                  <div>
                    <dl className="definition-list gap-8">
                      <dt>First and last name</dt>
                      <dd>
                        {txDetails.sender.firstname} {txDetails.sender.lastname}
                      </dd>
                      <dt>Id number</dt>
                      <dd>
                        {txDetails.sender.id_number}
                      </dd>

                      {Object.keys(omit(txDetails.sender, ['firstname', 'lastname', 'id_number'])).map(
                        keyName => (
                          <Fragment key={keyName}>
                            {txDetails.sender[keyName as keyof SenderForNewTxDetailsForm] && (
                              <>
                                <dt>{transactionUtils.getLabelOfTxDetails(keyName)}</dt>
                                <dd>
                                  {txDetails.sender[keyName as keyof SenderForNewTxDetailsForm]}
                                </dd>
                              </>
                            )}
                          </Fragment>
                        )
                      )}
                    </dl>
                  </div>

                  <div className="mb-8">
                    <h4 className="mb-8">Recipient</h4>
                    <hr />
                  </div>

                  <div>
                    <dl className="definition-list gap-8">
                      {/* <dt>Address</dt>
                      <dd>789 Oak Street, Billing city, USA</dd> */}

                      <dt>First and last name</dt>
                      <dd>
                        {txDetails.beneficiary.firstname} {txDetails.beneficiary.lastname}
                      </dd>

                      {Object.keys(omit(txDetails.beneficiary, ['firstname', 'lastname'])).map(
                        keyName => (
                          <Fragment key={keyName}>
                            {txDetails.beneficiary[
                              keyName as keyof BeneficiaryForNewTxDetailsForm
                            ] && (
                              <>
                                <dt>{transactionUtils.getLabelOfTxDetails(keyName)}</dt>
                                <dd>
                                  {
                                    txDetails.beneficiary[
                                      keyName as keyof BeneficiaryForNewTxDetailsForm
                                    ]
                                  }
                                </dd>
                              </>
                            )}
                          </Fragment>
                        )
                      )}

                      {Object.keys(txDetails.creditPartyIdentifier).map(keyName => (
                        <Fragment key={keyName}>
                          {txDetails.creditPartyIdentifier[
                            keyName as keyof CreditPartyIdentifierForNewTxDetailsForm
                          ] && (
                            <>
                              <dt>{transactionUtils.getLabelOfTxDetails(keyName)}</dt>
                              <dd>
                                {
                                  txDetails.creditPartyIdentifier[
                                    keyName as keyof CreditPartyIdentifierForNewTxDetailsForm
                                  ]
                                }
                              </dd>
                            </>
                          )}
                        </Fragment>
                      ))}
                    </dl>
                  </div>

                  <hr />
                  <div>
                    <dl className="definition-list gap-8">
                      <dt>Purpose of transfer</dt>
                      <dd>
                        {transactionUtils.getPurposeOfRemittanceTextValue(
                          txDetails.purposeOfRemittance
                        )}
                      </dd>
                    </dl>
                  </div>

                  <hr />

                  <div className="button-group flex-wrap">
                    <button type="button" className="btn btn-tertiary" onClick={onCancelClick}>
                      Cancel
                    </button>

                    <button
                      type="button"
                      className="btn btn-secondary outline"
                      onClick={onClickBack}
                    >
                      Back
                    </button>

                    <button
                      type="button"
                      className="btn btn-primary confirm-button"
                      onClick={onConfirm}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ReviewScreen
