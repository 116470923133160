import React, { useMemo } from 'react'
import { Control } from 'react-hook-form'
import FormInput from './FormInput'
import FormDateInput from './FormDateInput'
import transactionUtils from '@utils/transaction'
import { NewTxDetailsFormType } from '@customTypes/transaction'
import FormCountrySelectorWithSearch from '@components/ui/formCountrySelectorWithSearch/FormCountrySelectorWithSearch'

type CommonBeneficiaryFormFieldProps = {
  name: string
  control: Control<NewTxDetailsFormType>
}

const CommonBeneficiaryFormField = ({ name, control }: CommonBeneficiaryFormFieldProps) => {
  const renderView = useMemo(() => {
    switch (name) {
      case 'code': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="beneficiary.code"
            control={control}
            placeholder="Identification code"
            label="Identification code"
            type="text"
            maxLength={32}
          />
        )
      }
      case 'date_of_birth': {
        return (
          // FormDateInput
          <FormDateInput<NewTxDetailsFormType>
            name="beneficiary.date_of_birth"
            control={control}
            label="Date of birth"
          />
        )
      }
      case 'country_of_birth_iso_code': {
        return (
          <FormCountrySelectorWithSearch<NewTxDetailsFormType>
            options={transactionUtils.countriesOptions}
            name="beneficiary.country_of_birth_iso_code"
            control={control}
            labelName="Country of birth"
          />
        )
      }
      case 'address': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="beneficiary.address"
            control={control}
            placeholder="Address"
            label="Address"
            type="text"
            maxLength={64}
          />
        )
      }
      case 'city': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="beneficiary.city"
            control={control}
            placeholder="City"
            label="City"
            type="text"
            maxLength={64}
          />
        )
      }
      case 'email': {
        return (
          <FormInput<NewTxDetailsFormType>
            name="beneficiary.email"
            control={control}
            placeholder="Email"
            label="Email"
            type="text"
            maxLength={64}
          />
        )
      }
      case 'country_iso_code': {
        return (
          <FormCountrySelectorWithSearch<NewTxDetailsFormType>
            options={transactionUtils.countriesOptions}
            name="beneficiary.country_iso_code"
            control={control}
            labelName="Country"
          />
        )
      }
      case 'nationality_country_iso_code': {
        return (
          <FormCountrySelectorWithSearch<NewTxDetailsFormType>
            options={transactionUtils.countriesOptions}
            name="beneficiary.nationality_country_iso_code"
            control={control}
            labelName="Country of nationality"
          />
        )
      }
    }
  }, [control, name])
  return renderView
}

export default CommonBeneficiaryFormField
