import React from 'react'
import Icons from '@images/icon-set.svg'

type IconProps = {
  name: string
  size: number
  color?: string
  onClick?: () => void
}

const Icon = ({ name, size, color, onClick }: IconProps) => (
  <svg
    width={size}
    height={size}
    style={color ? ({ '--icon-color': color } as React.CSSProperties) : undefined}
    {...(onClick && { onClick })}
  >
    <use xlinkHref={`${Icons}#${name}`} />
  </svg>
)

export default Icon
