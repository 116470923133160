import Icon from '@components/Icon'
import FormInput from '@components/newTx/formScreen/FormInput'
// import TextFieldForm from '@components/ui/TextFieldForm'
import { EmailFormType, LoginScreenState, SignUpArgsRequest } from '@customTypes/auth'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppSelector } from '@hooks/reduxHooks'
import loginYupSchemes from '@utils/yupSchemes/loginYupSchemes'
import { isEmpty } from 'lodash'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

type ResetPasswordFormProps = {
  onParentSubmit: (formData: EmailFormType) => void
  userData: SignUpArgsRequest
  setCurrentScreen: Dispatch<SetStateAction<LoginScreenState>>
}

const ResetPasswordForm = ({
  onParentSubmit,
  userData,
  setCurrentScreen,
}: ResetPasswordFormProps) => {
  const sendEmailToResetPasswordIsLoading = useAppSelector(
    state => state.auth.sendEmailToRestPasswordLoadState.isLoading
  )

  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const { handleSubmit, control, formState, setValue } = useForm<EmailFormType>({
    resolver: yupResolver(loginYupSchemes.emailFormScheme),
  })

  const setFormValue = useCallback(() => {
    setValue('email', userData.email)
  }, [setValue, userData.email])

  useEffect(() => {
    setFormValue()
  }, [setFormValue])

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState])

  const onSubmit = (dataForm: EmailFormType) => {
    onParentSubmit(dataForm)
  }

  const onBack = useCallback(() => {
    setCurrentScreen('sign-in')
  }, [setCurrentScreen])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="box d-flex flex-col gap-24 is-white">
        <div>
          <div className="d-flex align-items-center gap-8 mb-16">
            <button className="transparent-button" onClick={onBack}>
              <Icon name="arrow-left" size={20} />
            </button>
            <h4>Reset Password</h4>
          </div>
          <FormInput
            name="email"
            control={control}
            placeholder="Enter email"
            type="string"
            label="Email"
            maxLength={64}
            autoComplete="on"
          />
        </div>
        <button
          className="btn btn-primary w-100"
          disabled={isDisabledButton || sendEmailToResetPasswordIsLoading}
        >
          Reset Password
        </button>
      </form>
    </>
  )
}

export default ResetPasswordForm
