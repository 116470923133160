import { ReactNode, useCallback, useState } from 'react'
import { jsx } from '@emotion/react'
// import Button from '@atlaskit/button'
import * as Flags from 'country-flag-icons/react/3x2'

import Select, { OptionProps, SingleValue, StylesConfig } from 'react-select'
import { defaultTheme, components } from 'react-select'
import { selectStyles } from './styleConfig'
import { CountryOption } from '@customTypes/calculation'
// import { StateOption, stateOptions } from '../data'

const { colors } = defaultTheme

const CustomOption = (props: OptionProps<CountryOption>) => {
  const { label, data } = props

  const flagCode = props.data.isoCode2 as keyof typeof Flags

  const FlagComponents = Flags[`${flagCode}`]

  return (
    <>
      {/* <components.Input/> */}
      <components.Option {...props}>
        <div style={{ width: '28px', marginRight: '5px' }}>
          {/* TEST */}
          <FlagComponents />
        </div>
        <p
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%',
          }}
          title={label}
        >
          {label}
        </p>
        {props.data.cashInCurrency ? <p className="currency-code">{props.data.cashInCurrency}</p> : null}
      </components.Option>
    </>
  )
}

const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
}: {
  children?: ReactNode
  readonly isOpen: boolean
  readonly target: ReactNode
  readonly onClose: () => void
}) => (
  <div style={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
)

type CountrySelectorWithSearch = {
  selectorName: string
  selectorValue: CountryOption
  options: Array<CountryOption>
  onParentChange: (value: any, name: string) => void
}

export const CountrySelectorWithSearch = ({
  selectorName,
  selectorValue,
  options,
  onParentChange,
}: CountrySelectorWithSearch) => {
  const [isOpen, setIsOpen] = useState(false)

  const flagCode = selectorValue.isoCode2 as keyof typeof Flags

  const FlagComponents = Flags[`${flagCode}`]


  const onSelectorChange = useCallback(
    (value: SingleValue<CountryOption>) => {
      onParentChange(value, selectorName)

      setIsOpen(prevState => !prevState)
    },
    [onParentChange, selectorName]
  )

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <button
          type="button"
          //disabled
          // iconAfter={<ChevronDown />}
          onClick={() => setIsOpen(prev => !prev)}
          // isSelected={isOpen}
        >
          {selectorValue.value ? (
            <>
              <FlagComponents />
              {selectorValue.cashInCurrency}
              <ChevronDown />
            </>
          ) : (
            ''
          )}
        </button>
        // <Button
        //   iconAfter={<ChevronDown />}
        //   onClick={() => setIsOpen(prev => !prev)}
        //   isSelected={isOpen}
        // >
        //   {value ? `State: ${value.label}` : 'Select a State'}
        // </Button>
      }
    >
      <Select
        // autoFocus
        backspaceRemovesValue={false}
        components={{ DropdownIndicator, IndicatorSeparator: null, Option: CustomOption }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={onSelectorChange}
        options={options}
        placeholder="Search..."
        styles={selectStyles}
        tabSelectsValue={false}
        value={selectorValue}
        
      />
    </Dropdown>
  )
}

const Menu = (props: JSX.IntrinsicElements['div']) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)'
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  )
}
const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    className="test"
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
)

const Svg = (p: JSX.IntrinsicElements['svg']) => (
  <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
)
const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
)
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
)

export default CountrySelectorWithSearch
