import authServices from "@api/services/authServices"
import { AuthorizeRequestBody, Credentials, SignUpArgsRequest } from "@customTypes/auth"
import { CustomErrorResponse } from "@customTypes/common"
import { createAsyncThunk } from "@reduxjs/toolkit"
import localStorageData from "@utils/localStorage"
import userActionCreators from "./userActionCreators"
import { firebaseErrors } from "@utils/errors"
import { openErrorGlobalModal } from "@sliceActionCreators/globalErrorModalSlice"

const signInFirebaseWithGoogle = createAsyncThunk(
  'auth/signInFirebaseWithGoogle', async (_, { rejectWithValue, dispatch }) => {
    try {
      await authServices.signInFirebaseWithGoogle()

      // dispatch(clearEvent())

      return 'success'
    } catch (e) { const err = e as CustomErrorResponse }
  })

const authorizeUser = createAsyncThunk(
  'auth/authorize', async (arg: AuthorizeRequestBody, { rejectWithValue, dispatch }) => {
    const { accessToken } = arg
    // const body = omit(arg, ['refreshToken'])
    // const authData = { accessToken, refreshToken }

    try {
      const res = await authServices.authorizeUser(arg)

      localStorageData.setData("authData", accessToken)
      // dispatch(eventActionCreators.getEventList())
      // dispatch(eventActionCreators.getActiveEventForAuthorizedUser())
      dispatch(userActionCreators.getProfile())
      // dispatch(purchaseActionCreators.getOrdersList())

      // return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }

  }
)

const logOut = createAsyncThunk(
  'auth/logOut', async (_, { dispatch, rejectWithValue }) => {
    try {
      await authServices.logOutFirebase()

      localStorageData.clearAllData()

      // dispatch(setEventPageSection("main"))
    } catch (e) {

    }
  }
)

const sendEmailVerificationAfterSignUp = createAsyncThunk(
  'auth/sendEmailVerificationAfterSignUp', async (_, { dispatch }) => {
    try {
      await authServices.sendEmailVerificationAfterSignUp()

      // toast.success('Email has been sent!',
      //   { position: 'top-right', transition: Slide, pauseOnFocusLoss: false })

    } catch (e) {
      const err = e as { code: string }
      const errorCode = err.code
      const errorMessage = firebaseErrors[errorCode] || 'Something went wrong!'


      dispatch(openErrorGlobalModal({ message: errorMessage }))
    }
  })

const signUp = createAsyncThunk(
  'auth/signUp', async (args: SignUpArgsRequest, { dispatch, rejectWithValue }) => {
    const signUpBodyRequest = { email: args.email, password: args.password }
    const updateUserProfileBodyRequest = { firstName: args.firstName, lastName: args.lastName }
    try {
      await authServices.signUp(signUpBodyRequest)
      // authServices.sendEmailVerificationAfterSignUp()
      const res = await authServices.updateUserProfile(updateUserProfileBodyRequest)

      // dispatch(clearEvent())


      // dispatch(setEventPageSection("main"))
      return res
    } catch (e) {
      const err = e as { code: string }
      const errorCode = err.code
      const errorMessage = firebaseErrors[errorCode] || 'Something went wrong!'


      dispatch(openErrorGlobalModal({ message: errorMessage }))

      return rejectWithValue('error')
    }
  }
)

const signInViaEmailAndPassword = createAsyncThunk(
  'auth/signInViaEmailAndPassword',
  async (credentials: Credentials, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      await authServices.signInViaEmailAndPassword({ email: credentials.email, password: credentials.password })

      // dispatch(clearEvent())

      return fulfillWithValue('success')
    } catch (e) {

      const err = e as { code: string }
      const errorCode = err.code
      const errorMessage = firebaseErrors[errorCode] || 'Something went wrong!'


      dispatch(openErrorGlobalModal({ message: errorMessage }))

      return rejectWithValue('error')
    }
  })

const sendEmailToResetPassword = createAsyncThunk(
  'auth/sendEmailToRestPassword',
  async (email: string, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      await authServices.sendEmailToResetPassword(email)

      // toast.success('Email has been sent!',
      //   {
      //     position: 'top-right',
      //     transition: Slide,
      //     pauseOnFocusLoss: false,
      //     autoClose: 3000,
      //   })

      return fulfillWithValue('success')
    } catch (e) {
      const err = e as { code: string }
      const errorCode = err.code

      const errorMessage = firebaseErrors[errorCode] || 'Something went wrong!'


      dispatch(openErrorGlobalModal({ message: errorMessage }))

      return rejectWithValue('error')
    }
  })

const authActionCreators = {
  signInFirebaseWithGoogle,
  authorizeUser,
  logOut,
  signUp,
  signInViaEmailAndPassword,
  sendEmailVerificationAfterSignUp,
  sendEmailToResetPassword,
}

export default authActionCreators