import LoginViaSocialMedia from "@components/login/LoginViaSocialMedia"
import LoginViaEmailAndPassword from "@components/login/loginViaEmailAndPassword/LoginViaEmailAndPassword"

const LoginPage = () => {
  return (
    <section className="form-section">
      <div className="container container-lg">
        <div className="background-container d-flex justify-content-center">
          <div className="box is-transparent login-form-wrapper">
            <LoginViaEmailAndPassword />
            <div className="divider mt-16 mb-16">
              <hr />
              <div className="divider-icon">
                OR
              </div>
              <hr />
            </div>
            <LoginViaSocialMedia />
          </div>
        </div>
      </div>
    </section>
    // <div className="span-all-areas">
    //   <div className="centered-content align-self-start">
    //     <div className="event-data-wrapper align-self-center mt-32">
    //       <LoginViaEmailAndPassword/>
    //       <div className="title-with-lines">
    //         <hr/><span>OR</span><hr/>
    //       </div>
    //       <LoginViaSocialMedia />
    //       <div className="display-flex gap-16 mt-16 mb-32" style={{ maxWidth: '320px' }}>
    //         <span style={{ fontSize: '12px', textAlign: 'center' }}>
    //         By logging into your account or creating a new one, you agree to our Rules, Practices
    //         and{' '}
    //         <a
    //           className="link"
    //           href="https://mtzfront.s3.eu-west-3.amazonaws.com/MastreetzTermsOfUse%26PrivacyPolicy.pdf"
    //           target="_blank"
    //           rel="noreferrer noopener"
    //         >
    //           Privacy Policy
    //         </a>
    //         </span>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default LoginPage
