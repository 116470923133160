import FormInput from '@components/newTx/formScreen/FormInput'
import { EmailFormType, LoginScreenState, SignUpArgsRequest } from '@customTypes/auth'
import { yupResolver } from '@hookform/resolvers/yup'
import commonUtils from '@utils/common'
import loginYupSchemes from '@utils/yupSchemes/loginYupSchemes'
import { isEmpty } from 'lodash'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

type EmailFormProps = {
  onParentSubmit: (formData: EmailFormType) => void
  userData: SignUpArgsRequest
  setCurrentScreen: Dispatch<SetStateAction<LoginScreenState>>
}

const EmailForm = ({ onParentSubmit, userData, setCurrentScreen }: EmailFormProps) => {
  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const { handleSubmit, control, formState, setValue } = useForm<EmailFormType>({
    resolver: yupResolver(loginYupSchemes.emailFormScheme),
  })

  const setFormValue = useCallback(() => {
    setValue('email', userData.email)
  }, [setValue, userData.email])

  useEffect(() => {
    setFormValue()
  }, [setFormValue])

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState])

  const onSubmit = (dataForm: EmailFormType) => {
    const trimmedDataForm = commonUtils.trimObjectFields(dataForm) as EmailFormType
    
    onParentSubmit(trimmedDataForm)
  }

  const onClickSignUp = useCallback(()=>{
    setCurrentScreen('sign-up')
  },[setCurrentScreen])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="box d-flex flex-col gap-24 is-white">
        <div>
          <h4 className="mb-16">Sign In</h4>
          <FormInput
              name="email"
              control={control}
              placeholder="Enter email"
              label="Email"
              type="text"
              maxLength={64}
              autoComplete='on'
            />
        </div>
        {/* <TextFieldForm
          name="email"
          control={control}
          placeholder="Enter email"
          type="string"
          label="Email"
          maxLength={64}
          autoComplete="on"
        /> */}
        <button className="btn btn-primary w-100" disabled={isDisabledButton}>
          Next
        </button>
        <button className="btn btn-tertiary w-100" onClick={onClickSignUp}>Create an account</button>
      </form>
    </>
  )
}

export default EmailForm
