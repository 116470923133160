import Icon from '@components/Icon'
import dateUtils from '@utils/date'
import {
  ForwardedRef,
  HTMLProps,
  forwardRef,
  useMemo,
  useRef,
} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

type DatePickerButtonProps = {
  startDate: Date | undefined
  endDate: Date | undefined
}

const DatePickerButton = forwardRef(
  (
    { startDate, endDate, onClick }: HTMLProps<HTMLDivElement> & DatePickerButtonProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const modifiedStartDate = useMemo(() => {
      if (!startDate) {
        return ''
      }

      return dateUtils.getFormattedDate(startDate, 'dd/MM/yyyy')
    }, [startDate])

    const modifiedEndDate = useMemo(() => {
      if (!endDate) {
        return ''
      }

      return dateUtils.getFormattedDate(endDate, 'dd/MM/yyyy')
    }, [endDate])

    return (
      <div
        className="form-control-date"
        onClick={onClick}
        ref={ref}
        {...((modifiedStartDate || modifiedEndDate) && { style: { paddingRight: '32px' } })}
      >
        <div className="d-flex gap-8 align-items-center h-100">
          <div className="pr-16">{modifiedStartDate ? `${modifiedStartDate}` : 'Start date'}</div>
          <Icon name="arrow-right" size={16} />
          <div className="pr-16">{modifiedEndDate ? `${modifiedEndDate}` : 'End date'}</div>
          <Icon name="calendar" size={24} />
        </div>
      </div>
    )
  }
)

type DateRangeProps = {
  dateFrom: string
  dateTo: string
  onDateRangeChange: (values: Array<Date | null>) => void
}

const DateRange = ({ dateFrom, dateTo, onDateRangeChange }: DateRangeProps) => {
  const calRef = useRef(null)

  return (
    <div>
      <DatePicker
        ref={calRef}
        selectsRange={true}
        // startDate={startDate}
        startDate={dateFrom ? new Date(dateFrom) : undefined}
        endDate={dateTo ? new Date(dateTo) : undefined}
        onChange={onDateRangeChange}
        // monthsShown={2}
        isClearable
        customInput={
          <DatePickerButton
            startDate={dateFrom ? new Date(dateFrom) : undefined}
            endDate={dateTo ? new Date(dateTo) : undefined}
          />
        }
      ></DatePicker>
    </div>
  )
}

export default DateRange
