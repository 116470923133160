import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import Icon from '@components/Icon'
import { LoginScreenState, SignUpArgsRequest } from '@customTypes/auth'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppSelector } from '@hooks/reduxHooks'
import { isEmpty } from 'lodash'
import { useForm } from 'react-hook-form'
import FormInput from '@components/newTx/formScreen/FormInput'
import commonUtils from '@utils/common'
import loginYupSchemes from '@utils/yupSchemes/loginYupSchemes'

type SignUpFormProps = {
  onParentSubmit: (formData: SignUpArgsRequest) => void
  userData: SignUpArgsRequest
  setCurrentScreen: Dispatch<SetStateAction<LoginScreenState>>
}

const SignUpForm = ({ onParentSubmit, setCurrentScreen, userData }: SignUpFormProps) => {
  const signUpIsLoading = useAppSelector(state => state.auth.signUpLoadState.isLoading)

  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const { handleSubmit, control, formState, setValue } = useForm<SignUpArgsRequest>({
    resolver: yupResolver(loginYupSchemes.signUpFormScheme),
  })

  const setFormValue = useCallback(() => {
    setValue('email', userData.email)
    setValue('firstName', userData.firstName)
    setValue('lastName', userData.lastName)
  }, [setValue, userData.email, userData.firstName, userData.lastName])

  useEffect(() => {
    setFormValue()
  }, [setFormValue])

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState])

  const onSubmit = (dataForm: SignUpArgsRequest) => {
    const trimmedDataForm = commonUtils.trimObjectFields(dataForm) as SignUpArgsRequest

    onParentSubmit(trimmedDataForm)
  }

  const onBack = useCallback(() => {
    setCurrentScreen('email')
  }, [setCurrentScreen])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="box d-flex flex-col gap-24 is-white">
        <div>
          <div className="d-flex align-items-center gap-8 mb-16">
            <button className="transparent-button" onClick={onBack}>
              <Icon name="arrow-left" size={20} />
            </button>
            <h4>Sign Up</h4>
          </div>
          <FormInput
            name="email"
            control={control}
            placeholder="Enter email"
            type="string"
            label="Email"
            maxLength={64}
            autoComplete="on"
          />
        </div>
        <FormInput
          name="password"
          control={control}
          placeholder="Enter password"
          type="password"
          label="* Password"
          maxLength={32}
        />
        <FormInput
          name="firstName"
          control={control}
          placeholder="Enter first name"
          type="text"
          label="First Name"
          maxLength={64}
        />
        <FormInput
          name="lastName"
          control={control}
          placeholder="Enter last name"
          type="text"
          label="Last Name"
          maxLength={64}
        />
        <div className="form-control-hint">
          * Password must contain at least one: number (0-9), upper case letter [A-Z], lower case
          letter [a-z] and have min 8 symbols
        </div>
        <button
          className="btn btn-primary w-100"
          disabled={isDisabledButton || signUpIsLoading}
          type="submit"
        >
          Sign Up
        </button>
      </form>
    </>
  )
}

export default SignUpForm
