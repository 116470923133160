import React, { useCallback, useState } from 'react'
import logo from '@images/logo-dark.svg'
// import authActionCreators from '@actionCreators/authActionCreators'
import { Link, NavLink } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import authActionCreators from '@actionCreators/authActionCreators'

const Header = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user.user)
  const [isActive, setIsActive] = useState(false)

  const logOut = useCallback(async () => {
    dispatch(authActionCreators.logOut())
  }, [dispatch])

  const toggleActiveClass = () => {
    setIsActive(!isActive)
  }

  return (
    <header className="main-header">
      <div className="container container-lg d-flex align-items-center justify-content-between">
        <Link className="main-header-logo" to={`/`}>
          <img src={logo} alt="" width={137} height={40} />
        </Link>

          <nav className={isActive ? 'active' : ''}>
            <div className="main-header-navigation-wrapper">
              <ul className="main-header-navigation">
                {user ? (
                  <>
                    {user.userRole === 'Agent' && (
                      <>
                        <li>
                          <NavLink to="/new-transaction">New Transaction</NavLink>
                        </li>
                        <li>
                          <NavLink to="/transactions">Transactions</NavLink>
                        </li>
                      </>
                    )}
                    <li className="main-header-navigation-user">
                      Welcome,{' '}
                      <div>
                        <b>{user.name}</b>
                      </div>
                    </li>
                    <li className="main-header-navigation-logout">
                      <button onClick={logOut} className="btn btn-primary">
                        Log Out
                      </button>
                    </li>
                  </>
                ) : (
                  <li>
                    <Link to={'/login'} className="btn btn-primary">
                      Sign Up/ Login
                    </Link>
                  </li>
                )}
              </ul>

              <button className={`sandwitch-btn ${isActive ? 'active' : ''}`} onClick={toggleActiveClass}>
                <span></span>
              </button>
            </div>
          </nav>

        <button className={`sandwitch-btn ${isActive ? 'active' : ''}`} onClick={toggleActiveClass}>
          <span></span>
        </button>
      </div>
    </header>
  )
}

export default Header
