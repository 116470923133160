import { useState } from 'react'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'

type FormPhoneNumberInputProps<T extends FieldValues> = UseControllerProps<T> & {
  placeholder: string
  label: string
  autoComplete?: 'on' | 'off'
}

const FormPhoneNumberInput = <T extends FieldValues>({
  name,
  control,
  placeholder,
  label,
}: FormPhoneNumberInputProps<T>) => {
  const {
    field: { onChange, value },
    fieldState: { error: inputError },
  } = useController({
    name,
    control,
  })

  return (
    <div className="form-group">
      <label className="form-control-label" htmlFor={name}>{label}</label>
      <PhoneInput
        inputProps={{ id: name, placeholder }}
        defaultCountry="ua"
        value={value}
        onChange={phone => onChange(phone)}
      />
      <p className='form-control-error'>{inputError?.message}</p>
    </div>
  )
}

export default FormPhoneNumberInput
