import { Country, GetCountriesForCalculationResponseBody, MakeCalculationRequestBody, MakeCalculationResponseBody, Payer, Service } from "@customTypes/calculation"
import { anonymousRequest } from "./anonymousRequest"
import localStorageData from "@utils/localStorage"
import { authorizedRequest } from "@api/authorizeRequest"

const getCountriesForCalculation = () => {
  const path = ['money-transfer', 'countries']

  return anonymousRequest<null, GetCountriesForCalculationResponseBody>(path, "GET")
}

const getServices = (countryIsoCode: string) => {
  const path = ['money-transfer', 'services']
  const query = {
    countryIsoCode
  }

  return anonymousRequest<null, Array<Service>>(path, "GET", null, query)
}

const getPayers = (query: { receiveCountryCode: string, receiveCurrencyCode: string, serviceId: number }) => {
  const path = ['money-transfer', 'payers']
  // const query = {
  //   receiveCountryCode,
  //   serviceId
  // }

  return anonymousRequest<null, Array<Payer>>(path, "GET", null, query)
}

const makeCalculation = (body: MakeCalculationRequestBody) => {
  const path = ['money-transfer', 'calculate']
  const token = localStorageData.getData('authData')

  if (token) {
    return authorizedRequest<MakeCalculationRequestBody, MakeCalculationResponseBody>('v1', path, "POST", body)
  } else {
    return anonymousRequest<MakeCalculationRequestBody, MakeCalculationResponseBody>(path, "POST", body)
  }


}


export const calculationServices = {
  getCountriesForCalculation,
  getServices,
  getPayers,
  makeCalculation,
}