import { transactionServices } from "@api/services/transactionServices"
import { CustomErrorResponse } from "@customTypes/common"
import { CreateTxRequestBody, GetTransactionListRequestArg } from "@customTypes/transaction"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { openErrorGlobalModal } from "@sliceActionCreators/globalErrorModalSlice"

const createTransaction = createAsyncThunk(
  'transaction/createTransaction',
  //CreateTxRequestBody
  async (body: FormData, { dispatch, rejectWithValue }) => {
    try {

      const res = await transactionServices.createTx(body)

      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      dispatch(openErrorGlobalModal(err))

      return rejectWithValue('error')
    }
  }
)

const getTransactionList = createAsyncThunk(
  'transaction/getTransactionList',
  async (arg: GetTransactionListRequestArg, { rejectWithValue }) => {
    const { page, limit, query } = arg

    try {

      const res = await transactionServices.getTransactionList(page, limit, query)

      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  }
)

const getTransactionDetailsById = createAsyncThunk(
  'transaction/getTransactionDetailsById',
  async (id: string, { rejectWithValue }) => {
    try {

      const res = await transactionServices.getTransactionDetailsById(id)

      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  }
)

const getTransactionLogsById = createAsyncThunk(
  'transaction/getTransactionLogsById',
  async (id: string, { rejectWithValue }) => {
    try {

      const res = await transactionServices.getTransactionLogsById(id)

      console.log(res)

      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  }
)






export const transactionActionCreators = {
  createTransaction,
  getTransactionList,
  getTransactionDetailsById,
  getTransactionLogsById,
}