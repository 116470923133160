import { format } from "date-fns/format"
import { isExists } from 'date-fns/isExists'

const getFormattedDate = (date: string | Date, fmt: string) =>
  date ? format(new Date(date), fmt) : ''

const checkForDateExistence = (date: string | undefined) => {
  if (!date) {
    return false
  }
  const [year, month, day] = date.split('-').map(dateItem => Number(dateItem))
  const currentYear = new Date().getFullYear()

  // if (date.trim().length !== 10) {
  //   return false
  // }
  if (date.trim().length !== 10 || !isExists(Number(year), Number(month) - 1, Number(day))) {
    return false
  }

  return currentYear > Number(year) && Number(year) >= 1930
}

const dateUtils = {
  getFormattedDate,
  checkForDateExistence
}

export default dateUtils