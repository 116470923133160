import { SvgIconProps } from '@customTypes/common'

export namespace SocialMediaIcons {
  export function Google() {
    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.38 16.585C27.38 15.7814 27.3079 15.0086 27.1739 14.2668H16.5V18.6508H22.5994C22.3367 20.0674 21.5382 21.2677 20.3379 22.0714V24.915H24.0006C26.1436 22.942 27.38 20.0365 27.38 16.585Z"
          fill="#4285F4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 27.6608C19.56 27.6608 22.1254 26.6459 24.0006 24.915L20.3379 22.0714C19.323 22.7514 18.0248 23.1532 16.5 23.1532C13.5482 23.1532 11.0497 21.1595 10.1585 18.4807H6.37209V21.4171C8.23694 25.1211 12.0697 27.6608 16.5 27.6608Z"
          fill="#34A853"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1584 18.4808C9.93178 17.8008 9.80299 17.0744 9.80299 16.3275C9.80299 15.5805 9.93178 14.8541 10.1584 14.1741V11.2378H6.37207C5.6045 12.7678 5.16662 14.4987 5.16662 16.3275C5.16662 18.1563 5.6045 19.8872 6.37207 21.4172L10.1584 18.4808Z"
          fill="#FBBC05"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 9.50169C18.1639 9.50169 19.6579 10.0735 20.8324 11.1965L24.083 7.94593C22.1203 6.11714 19.5548 4.99411 16.5 4.99411C12.0697 4.99411 8.23694 7.53381 6.37209 11.2378L10.1585 14.1741C11.0497 11.4953 13.5482 9.50169 16.5 9.50169Z"
          fill="#EA4335"
        />
      </svg>
    )
  }
  export function Facebook({ width = 32, height = 32 }: SvgIconProps) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5 28.1941C8.83333 27.1941 4.5 22.2607 4.5 16.3274C4.5 9.72739 9.9 4.32739 16.5 4.32739C23.1 4.32739 28.5 9.72739 28.5 16.3274C28.5 22.2607 24.1667 27.1941 18.5 28.1941L17.8333 27.6607H15.1667L14.5 28.1941Z"
          fill="url(#paint0_linear_60_4454)"
        />
        <path
          d="M21.1667 19.6607L21.7 16.3274H18.5V13.9941C18.5 13.0607 18.8333 12.3274 20.3 12.3274H21.8333V9.26075C20.9667 9.12741 20.0333 8.99408 19.1667 8.99408C16.4333 8.99408 14.5 10.6607 14.5 13.6607V16.3274H11.5V19.6607H14.5V28.1274C15.1667 28.2607 15.8333 28.3274 16.5 28.3274C17.1667 28.3274 17.8333 28.2607 18.5 28.1274V19.6607H21.1667Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_60_4454"
            x1="16.5"
            y1="27.4967"
            x2="16.5"
            y2="4.32739"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0062E0" />
            <stop offset="1" stopColor="#19AFFF" />
          </linearGradient>
        </defs>
      </svg>
    )
  }
  export function Instagram({ width = 32, height = 32 }: SvgIconProps) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <rect x="2" y="2" width="28" height="28" rx="6" fill="url(#paint0_radial_87_7153)"></rect>{' '}
          <rect x="2" y="2" width="28" height="28" rx="6" fill="url(#paint1_radial_87_7153)"></rect>{' '}
          <rect x="2" y="2" width="28" height="28" rx="6" fill="url(#paint2_radial_87_7153)"></rect>{' '}
          <path
            d="M23 10.5C23 11.3284 22.3284 12 21.5 12C20.6716 12 20 11.3284 20 10.5C20 9.67157 20.6716 9 21.5 9C22.3284 9 23 9.67157 23 10.5Z"
            fill="white"
          ></path>{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21ZM16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z"
            fill="white"
          ></path>{' '}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 15.6C6 12.2397 6 10.5595 6.65396 9.27606C7.2292 8.14708 8.14708 7.2292 9.27606 6.65396C10.5595 6 12.2397 6 15.6 6H16.4C19.7603 6 21.4405 6 22.7239 6.65396C23.8529 7.2292 24.7708 8.14708 25.346 9.27606C26 10.5595 26 12.2397 26 15.6V16.4C26 19.7603 26 21.4405 25.346 22.7239C24.7708 23.8529 23.8529 24.7708 22.7239 25.346C21.4405 26 19.7603 26 16.4 26H15.6C12.2397 26 10.5595 26 9.27606 25.346C8.14708 24.7708 7.2292 23.8529 6.65396 22.7239C6 21.4405 6 19.7603 6 16.4V15.6ZM15.6 8H16.4C18.1132 8 19.2777 8.00156 20.1779 8.0751C21.0548 8.14674 21.5032 8.27659 21.816 8.43597C22.5686 8.81947 23.1805 9.43139 23.564 10.184C23.7234 10.4968 23.8533 10.9452 23.9249 11.8221C23.9984 12.7223 24 13.8868 24 15.6V16.4C24 18.1132 23.9984 19.2777 23.9249 20.1779C23.8533 21.0548 23.7234 21.5032 23.564 21.816C23.1805 22.5686 22.5686 23.1805 21.816 23.564C21.5032 23.7234 21.0548 23.8533 20.1779 23.9249C19.2777 23.9984 18.1132 24 16.4 24H15.6C13.8868 24 12.7223 23.9984 11.8221 23.9249C10.9452 23.8533 10.4968 23.7234 10.184 23.564C9.43139 23.1805 8.81947 22.5686 8.43597 21.816C8.27659 21.5032 8.14674 21.0548 8.0751 20.1779C8.00156 19.2777 8 18.1132 8 16.4V15.6C8 13.8868 8.00156 12.7223 8.0751 11.8221C8.14674 10.9452 8.27659 10.4968 8.43597 10.184C8.81947 9.43139 9.43139 8.81947 10.184 8.43597C10.4968 8.27659 10.9452 8.14674 11.8221 8.0751C12.7223 8.00156 13.8868 8 15.6 8Z"
            fill="white"
          ></path>{' '}
          <defs>
            <radialGradient
              id="paint0_radial_87_7153"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(12 23) rotate(-55.3758) scale(25.5196)"
            >
              <stop stopColor="#B13589"></stop> <stop offset="0.79309" stopColor="#C62F94"></stop>{' '}
              <stop offset="1" stopColor="#8A3AC8"></stop>{' '}
            </radialGradient>{' '}
            <radialGradient
              id="paint1_radial_87_7153"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(11 31) rotate(-65.1363) scale(22.5942)"
            >
              {' '}
              <stop stopColor="#E0E8B7"></stop> <stop offset="0.444662" stopColor="#FB8A2E"></stop>{' '}
              <stop offset="0.71474" stopColor="#E2425C"></stop>{' '}
              <stop offset="1" stopColor="#E2425C" stopOpacity="0"></stop>{' '}
            </radialGradient>{' '}
            <radialGradient
              id="paint2_radial_87_7153"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(0.500002 3) rotate(-8.1301) scale(38.8909 8.31836)"
            >
              {' '}
              <stop offset="0.156701" stopColor="#406ADC"></stop>{' '}
              <stop offset="0.467799" stopColor="#6A45BE"></stop>{' '}
              <stop offset="1" stopColor="#6A45BE" stopOpacity="0"></stop>{' '}
            </radialGradient>{' '}
          </defs>{' '}
        </g>
      </svg>
    )
  }
  export function TikTok() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33" width="32" height="32">
        <path
          d="M10.209 19.4879C10.3393 18.4096 10.7815 17.8059 11.6149 17.1871C12.8072 16.3489 14.2967 16.823 14.2967 16.823V14.0104C14.6588 14.0011 15.021 14.0226 15.3792 14.0745V17.6941C15.3792 17.6941 13.8902 17.22 12.6978 18.0586C11.8649 18.677 11.4218 19.2812 11.292 20.3594C11.2879 20.9449 11.3978 21.7103 11.9038 22.372C11.7787 22.3079 11.6512 22.2348 11.5213 22.1527C10.4067 21.4043 10.2036 20.2814 10.209 19.4879ZM21.5292 8.80983C20.709 7.911 20.3988 7.0035 20.2866 6.366H21.3185C21.3185 6.366 21.1128 8.03754 22.6122 9.68135L22.633 9.70346C22.2289 9.44926 21.8583 9.14921 21.5292 8.80983ZM26.5 11.3581V14.9049C26.5 14.9049 25.1832 14.8533 24.2088 14.6054C22.8482 14.2587 21.9737 13.727 21.9737 13.727C21.9737 13.727 21.3696 13.3477 21.3208 13.3213V20.6454C21.3208 21.0532 21.2091 22.0717 20.8686 22.9211C20.4241 24.0323 19.7382 24.7617 19.612 24.9108C19.612 24.9108 18.7777 25.8967 17.3059 26.5606C15.9792 27.1596 14.8144 27.1444 14.4663 27.1596C14.4663 27.1596 12.4527 27.2393 10.6409 26.0618C10.2491 25.8023 9.88334 25.5083 9.54842 25.1838L9.55747 25.1903C11.3698 26.3678 13.3829 26.288 13.3829 26.288C13.7315 26.2729 14.8963 26.288 16.2225 25.6891C17.693 25.0252 18.5286 24.0392 18.5286 24.0392C18.6534 23.8902 19.3425 23.1608 19.7852 22.0492C20.1248 21.2002 20.2374 20.1813 20.2374 19.7735V12.4502C20.2862 12.4771 20.8898 12.8563 20.8898 12.8563C20.8898 12.8563 21.7648 13.3885 23.1254 13.7348C24.1003 13.9827 25.4166 14.0342 25.4166 14.0342V11.255C25.8669 11.3559 26.2508 11.3832 26.5 11.3581Z"
          fill="#FD355A"
        />
        <path
          d="M25.417 11.255V14.0334C25.417 14.0334 24.1007 13.9818 23.1259 13.7339C21.7653 13.3872 20.8903 12.8554 20.8903 12.8554C20.8903 12.8554 20.2867 12.4762 20.2378 12.4494V19.7743C20.2378 20.1822 20.1261 21.201 19.7856 22.05C19.3412 23.1616 18.6552 23.891 18.5291 24.0401C18.5291 24.0401 17.6943 25.026 16.223 25.69C14.8967 26.2889 13.7319 26.2737 13.3833 26.2889C13.3833 26.2889 11.3702 26.3687 9.55792 25.1912L9.54888 25.1847C9.35754 24.9994 9.17743 24.8038 9.00944 24.5987C8.43111 23.8936 8.0766 23.0598 7.98753 22.8219C7.98737 22.8209 7.98737 22.8198 7.98753 22.8188C7.84419 22.4054 7.54304 21.4125 7.58419 20.4508C7.65699 18.7542 8.25386 17.7128 8.41166 17.4519C8.82961 16.7404 9.3732 16.1039 10.0182 15.5706C10.5874 15.1104 11.2326 14.7442 11.9273 14.4871C12.6783 14.1852 13.4825 14.0234 14.2967 14.0104V16.823C14.2967 16.823 12.8072 16.3506 11.6153 17.1871C10.782 17.8059 10.3397 18.4096 10.2095 19.4879C10.2041 20.2814 10.4071 21.4043 11.5208 22.1532C11.6507 22.2355 11.7782 22.3086 11.9033 22.3725C12.0979 22.6254 12.3347 22.8457 12.6042 23.0247C13.6921 23.7133 14.6037 23.7614 15.7694 23.3142C16.5467 23.0152 17.1318 22.3413 17.4031 21.5945C17.5736 21.1282 17.5713 20.6589 17.5713 20.1735V6.366H20.2844C20.3965 7.0035 20.7067 7.911 21.527 8.80983C21.856 9.14921 22.2266 9.44926 22.6307 9.70346C22.7501 9.82697 23.3605 10.4376 24.1441 10.8125C24.5493 11.0062 24.9764 11.1547 25.417 11.255Z"
          fill="black"
        />
        <path
          d="M6.90771 21.9582V21.9604L6.97503 22.1428C6.9673 22.1216 6.94228 22.057 6.90771 21.9582Z"
          fill="#33F3ED"
        />
        <path
          d="M11.9273 14.4871C11.2327 14.7442 10.5875 15.1104 10.0183 15.5706C9.37302 16.1051 8.82957 16.743 8.41215 17.4558C8.25434 17.7158 7.65747 18.7581 7.58467 20.4548C7.54352 21.4165 7.84467 22.4093 7.98801 22.8228C7.98786 22.8238 7.98786 22.8248 7.98801 22.8258C8.07845 23.0616 8.43159 23.8954 9.00992 24.6027C9.17792 24.8077 9.35803 25.0033 9.54937 25.1886C8.93631 24.7822 8.38955 24.2909 7.92697 23.7307C7.35361 23.0317 7.00001 22.2065 6.90777 21.963C6.90766 21.9612 6.90766 21.9595 6.90777 21.9578V21.9547C6.76398 21.5417 6.46193 20.5484 6.50398 19.5854C6.57678 17.8887 7.17365 16.8473 7.33145 16.5864C7.74875 15.8736 8.29222 15.2356 8.93758 14.7012C9.50667 14.2409 10.1519 13.8747 10.8467 13.6178C11.28 13.4455 11.7315 13.3183 12.1932 13.2386C12.8891 13.1222 13.5998 13.1121 14.299 13.2087V14.0104C13.484 14.0232 12.679 14.185 11.9273 14.4871Z"
          fill="#33F3ED"
        />
        <path
          d="M20.2866 6.36601H17.5736V20.1739C17.5736 20.6593 17.5736 21.1274 17.4054 21.595C17.1314 22.3413 16.5485 23.0152 15.7717 23.3142C14.6055 23.7632 13.6939 23.7133 12.6065 23.0247C12.3365 22.8466 12.0991 22.6269 11.9038 22.3746C12.8303 22.8483 13.6596 22.8401 14.6869 22.4448C15.4633 22.1458 16.0471 21.4719 16.3202 20.7252C16.4911 20.2589 16.4888 19.7895 16.4888 19.3046V5.49405H20.2351C20.2351 5.49405 20.193 5.83729 20.2866 6.36601ZM25.417 10.4866V11.255C24.9772 11.1546 24.5509 11.0061 24.1464 10.8125C23.3628 10.4376 22.7523 9.82698 22.633 9.70347C22.7715 9.79063 22.9152 9.86993 23.0634 9.94096C24.0162 10.3969 24.9544 10.533 25.417 10.4866Z"
          fill="#33F3ED"
        />
      </svg>
    )
  }
  export function WhatsApp({ width = 32, height = 32 }: SvgIconProps) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.1425 5.91677C30.6001 6.13585 34.7538 7.97404 37.9263 11.1487C41.3103 14.5348 43.1729 19.0359 43.1711 23.8226C43.167 33.7003 35.1257 41.7373 25.2474 41.7373C21.5326 41.7373 18.5807 40.5959 16.6746 39.5568L7.17285 42.048L9.71573 32.7645C8.1472 30.0477 7.32185 26.9659 7.32314 23.8083C7.32711 13.9308 15.3677 5.89462 25.2471 5.89462L26.1425 5.91677ZM17.1148 36.3136L17.6588 36.6362C19.9461 37.9929 22.568 38.7107 25.2413 38.7118H25.2473C33.4585 38.7118 40.1415 32.0319 40.1448 23.8215C40.1463 19.8429 38.5982 16.1018 35.7855 13.2873C32.9728 10.4727 29.2324 8.92171 25.2532 8.92042C17.0358 8.92042 10.3527 15.5996 10.3494 23.8094C10.3483 26.623 11.1359 29.3631 12.6271 31.7337L12.9814 32.2972L11.4764 37.7918L17.1148 36.3136ZM34.2772 28.0827C34.1653 27.8958 33.8668 27.7839 33.4189 27.5598C32.971 27.3357 30.7692 26.2528 30.3587 26.1033C29.9481 25.9541 29.6495 25.8795 29.351 26.3275C29.0525 26.7755 28.1942 27.7839 27.9329 28.0827C27.6717 28.3814 27.4104 28.4188 26.9627 28.1947C26.5148 27.9707 25.0717 27.4979 23.3611 25.9729C22.0297 24.7859 21.1309 23.3201 20.8696 22.872C20.6084 22.4239 20.8418 22.1815 21.066 21.9584C21.2675 21.7578 21.5139 21.4355 21.7378 21.1742C21.9618 20.9128 22.0363 20.726 22.1857 20.4274C22.3349 20.1286 22.2603 19.8673 22.1483 19.6432C22.0363 19.419 21.1407 17.2157 20.7675 16.3194C20.4039 15.4466 20.0347 15.5648 19.7598 15.551C19.4989 15.5381 19.2 15.5352 18.9015 15.5352C18.6029 15.5352 18.1177 15.6472 17.7072 16.0954C17.2967 16.5435 16.1397 17.6265 16.1397 19.8297C16.1397 22.0331 17.7445 24.1615 17.9685 24.4603C18.1924 24.7592 21.1265 29.2803 25.6191 31.2195C26.6876 31.6807 27.5218 31.9561 28.1722 32.1624C29.2451 32.5031 30.2214 32.4551 30.9931 32.3397C31.8536 32.2112 33.6428 31.2569 34.016 30.2114C34.3892 29.1655 34.3892 28.2693 34.2772 28.0827Z"
          fill="white"
        />
        <mask
          id="mask0_60_4399"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="10"
          y="8"
          width="31"
          height="31"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.1147 36.3136L17.6588 36.6362C19.9461 37.9929 22.568 38.7107 25.2412 38.7118H25.2473C33.4584 38.7118 40.1414 32.0319 40.1447 23.8215C40.1463 19.8429 38.5982 16.1018 35.7855 13.2873C32.9727 10.4727 29.2323 8.92173 25.2532 8.92044C17.0357 8.92044 10.3526 15.5996 10.3494 23.8094C10.3482 26.623 11.1358 29.3631 12.6271 31.7337L12.9813 32.2972L11.4763 37.7919L17.1147 36.3136ZM34.2772 28.0827C34.1652 27.8958 33.8667 27.7839 33.4189 27.5598C32.971 27.3357 30.7691 26.2528 30.3586 26.1033C29.9481 25.9541 29.6495 25.8795 29.351 26.3275C29.0525 26.7755 28.1942 27.7839 27.9328 28.0827C27.6717 28.3814 27.4104 28.4188 26.9626 28.1947C26.5148 27.9707 25.0717 27.4979 23.3611 25.9729C22.0297 24.7859 21.1308 23.3201 20.8696 22.872C20.6084 22.4239 20.8417 22.1816 21.0659 21.9584C21.2674 21.7578 21.5139 21.4356 21.7378 21.1742C21.9617 20.9129 22.0363 20.726 22.1856 20.4274C22.3349 20.1286 22.2602 19.8673 22.1483 19.6432C22.0363 19.4191 21.1407 17.2157 20.7675 16.3194C20.4039 15.4466 20.0347 15.5648 19.7598 15.551C19.4989 15.5381 19.2 15.5352 18.9015 15.5352C18.6029 15.5352 18.1177 15.6472 17.7072 16.0954C17.2966 16.5435 16.1397 17.6265 16.1397 19.8297C16.1397 22.0332 17.7445 24.1615 17.9684 24.4603C18.1924 24.7592 21.1264 29.2804 25.6191 31.2195C26.6876 31.6807 27.5218 31.9561 28.1722 32.1624C29.2451 32.5031 30.2214 32.4551 30.9931 32.3398C31.8535 32.2113 33.6428 31.2569 34.016 30.2114C34.3892 29.1656 34.3892 28.2694 34.2772 28.0827Z"
            fill="black"
          />
        </mask>
        <g mask="url(#mask0_60_4399)">
          <path d="M49.8599 -1H-0.5V49.36H49.8599V-1Z" fill="url(#paint0_linear_60_4399)" />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_60_4399"
            x1="24.68"
            y1="49.36"
            x2="24.68"
            y2="-1"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#25CF43" />
            <stop offset="1" stopColor="#61FD7D" />
          </linearGradient>
        </defs>
      </svg>
    )
  }
  export function X({ width = 32, height = 32 }: SvgIconProps) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_60_4404)">
          <path
            d="M24.479 41.6721C14.7737 41.6373 6.95717 33.7792 6.99993 24.1012C7.04319 14.4186 14.9154 6.63377 24.6237 6.6726C34.2749 6.71143 42.0397 14.6093 41.9994 24.3461C41.9597 33.8937 34.0586 41.707 24.479 41.6721ZM13.6072 14.3395C16.3473 17.9307 19.0501 21.4742 21.7623 25.0291C19.1699 28.012 16.584 30.987 13.9598 34.0062C14.0915 34.0062 14.1626 34.0062 14.2337 34.0062C15.1868 34.0062 16.14 34.0017 17.0931 34.0107C17.2612 34.0122 17.3661 33.953 17.4715 33.8325C19.3852 31.6391 21.3019 29.4487 23.2167 27.2563C23.2634 27.2031 23.2902 27.1324 23.3345 27.0567C23.4379 27.1856 23.4906 27.2494 23.5408 27.3146C25.1771 29.4557 26.8164 31.5948 28.4447 33.7419C28.5964 33.9415 28.7495 34.0132 28.9971 34.0122C31.0277 34.0017 33.0588 34.0062 35.0893 34.0062C35.1768 34.0062 35.2643 34.0062 35.4065 34.0062C32.5188 30.185 29.6634 26.4066 26.802 22.6212C29.2214 19.8637 31.6248 17.1248 34.0686 14.3395C33.9254 14.3395 33.8463 14.3395 33.7668 14.3395C32.8385 14.3395 31.9102 14.3445 30.9819 14.335C30.791 14.333 30.6632 14.3898 30.537 14.5336C29.1786 16.0779 27.8143 17.6166 26.4509 19.1568C26.0457 19.6148 25.6395 20.0723 25.2219 20.5433C25.1493 20.4502 25.0921 20.38 25.0379 20.3083C23.5781 18.3768 22.1168 16.4462 20.661 14.5117C20.5671 14.3868 20.4721 14.335 20.3125 14.336C19.0113 14.343 17.7096 14.34 16.4085 14.34C15.4971 14.34 14.5862 14.34 13.6072 14.34V14.3395Z"
            fill="black"
          />
          <path
            d="M17.4829 16.1281C18.1412 16.1281 18.7592 16.1212 19.3763 16.1366C19.4568 16.1386 19.5513 16.2441 19.6114 16.3233C21.3541 18.6217 23.0933 20.9226 24.833 23.223C26.9879 26.072 29.1428 28.921 31.2977 31.7705C31.3509 31.8407 31.3991 31.9154 31.4786 32.0299C30.8591 32.0299 30.2834 32.0379 29.7091 32.0209C29.6295 32.0184 29.54 31.9034 29.4779 31.8233C28.126 30.0605 26.7776 28.2953 25.4282 26.531C22.8343 23.1409 20.2404 19.7512 17.647 16.3606C17.5983 16.2974 17.5545 16.2302 17.4834 16.1281H17.4829Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_60_4404">
            <rect width="35" height="35" fill="white" transform="translate(7 6.67261)" />
          </clipPath>
        </defs>
      </svg>
    )
  }

  export function YouTube({ width = 32, height = 32 }: SvgIconProps) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-label="YouTube"
        role="img"
        viewBox="0 0 512 512"
        fill="#ed1d24"
        width={width}
        height={height}
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <rect width="512" height="512" rx="15%"></rect>
          <path
            d="m427 169c-4-15-17-27-32-31-34-9-239-10-278 0-15 4-28 16-32 31-9 38-10 135 0 174 4 15 17 27 32 31 36 10 241 10 278 0 15-4 28-16 32-31 9-36 9-137 0-174"
            fill="#ffffff"
          ></path>
          <path d="m220 203v106l93-53"></path>
        </g>
      </svg>
    )
  }
}
