import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail
} from 'firebase/auth'
import { auth } from '@fire-base/mainFirebase'
import { AuthorizeRequestBody, Credentials, UserDetails } from '@customTypes/auth'
import localStorageData from '@utils/localStorage'
import { anonymousRequest } from './anonymousRequest'
import { User } from '@customTypes/user'


const signInFirebaseWithGoogle = () => {
  const provider = new GoogleAuthProvider()
  provider.addScope('https://www.googleapis.com/auth/userinfo.email')

  return signInWithPopup(auth, provider)
}

const logOut = () => {
  localStorageData.clearAllData()
}

const logOutFirebase = () => {
  signOut(auth)

  logOut()
}

const authorizeUser = (body: AuthorizeRequestBody) => {
  const path = ['authorize']

  return anonymousRequest<AuthorizeRequestBody, User>(path, "POST", body)

}

const signInViaEmailAndPassword = (credentials: Credentials) => {

  return signInWithEmailAndPassword(auth, credentials.email, credentials.password)

}

const sendEmailVerificationAfterSignUp = () => {

  return auth.currentUser && sendEmailVerification(auth.currentUser)

}

const signUp = (credentials: Credentials) => {

  return createUserWithEmailAndPassword(auth, credentials.email, credentials.password)

}

const updateUserProfile = (userData: UserDetails) => {
  const currentUser = auth.currentUser

  if (currentUser) {
    return updateProfile(currentUser, {
      displayName: `${userData.firstName} ${userData.lastName}`
    })
  }
}

const sendEmailToResetPassword = (email: string) => {
  return sendPasswordResetEmail(auth, email)
}

const authServices = {
  signInFirebaseWithGoogle,
  logOutFirebase,
  authorizeUser,
  logOut,
  signUp,
  updateUserProfile,
  signInViaEmailAndPassword,
  sendEmailVerificationAfterSignUp,
  sendEmailToResetPassword,
}

export default authServices