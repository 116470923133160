import { CountryOption } from "@customTypes/calculation";
import { StylesConfig } from "react-select";


export const selectStyles: StylesConfig<CountryOption, false> = {
  control: provided => ({
    ...provided,
    minWidth: 240,
    margin: 8,
  }),
  container: provided => ({
    ...provided,
   maxWidth: 270,
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
}