import userServices from "@api/services/userServices"
import { CustomErrorResponse } from "@customTypes/common"
import { createAsyncThunk } from "@reduxjs/toolkit"

const getProfile = createAsyncThunk(
  'user/getProfile', async (_, { rejectWithValue, dispatch }) => {

    try {
      const res = await userServices.getProfile()

      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  }
)

const userActionCreators = {
  getProfile
}

export default userActionCreators