import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import InputWithCountrySelector from './InputWithCountries/InputWithCountrySelector'
import { CalculateMode, CommonOption, CountryOption } from '@customTypes/calculation'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import CustomSelector from './ui/CustomSelector/CustomSelector'
import { selectStyles } from './ui/CustomSelector/styleConfig'
import debounce from 'lodash/debounce'
import { calculationActionCreators } from '@actionCreators/calculationActionCreators'

export type CalculationFormDataType = {
  srcCountry: {
    value: number
    isoCode3: string
    isoCode2: string
    label: string
    cashInCurrency: string
  }
  destCountry: {
    value: number
    isoCode3: string
    isoCode2: string
    label: string
    cashInCurrency: string
  }
  services?: {
    value: number
    label: string
  }
  payers?: {
    value: number
    label: string
  }
  srcAmount: string
  destAmount: string
  mode: CalculateMode
}

type CalculationFormProps = {
  isShownSubmitBtn: boolean
  onParentSubmit?: (dataForm: CalculationFormDataType) => void
}

const CalculationForm = ({ isShownSubmitBtn, onParentSubmit }: CalculationFormProps) => {
  const dispatch = useAppDispatch()

  const [formData, setFormData] = useState({
    srcCountry: {
      value: 0,
      isoCode3: '',
      isoCode2: '',
      label: '',
      cashInCurrency: '',
    },
    destCountry: {
      value: 0,
      isoCode3: '',
      isoCode2: '',
      label: '',
      cashInCurrency: '',
    },
    services: {
      value: 0,
      label: '',
    },
    payers: {
      value: 0,
      label: '',
    },
    srcAmount: '100.00',
    destAmount: '0',
    mode: 'SOURCE_AMOUNT',
  })

  const {
    makeCalculationLoadState,
    sourceCountry,
    destCountry,
    services,
    payers,
    calculationInfo,
  } = useAppSelector(state => state.calculation)

  const isFirstInitializeRef = useRef(false)

  const servicesOptions = useMemo(() => {
    return services.map(service => ({ value: service.id, label: service.name }))
  }, [services])

  const payersOptions = useMemo(() => {
    return payers.map(payer => ({ value: payer.id, label: payer.name }))
  }, [payers])

  const sourceCountryOptions = useMemo(
    () =>
      sourceCountry.map(country => ({
        value: country.id,
        isoCode3: country.isoCode3,
        isoCode2: country.isoCode2,
        label: country.name,
        cashInCurrency: country.cashInCurrency,
      })),
    [sourceCountry]
  )

  const destCountryOptions = useMemo(
    () =>
      destCountry.map(country => ({
        value: country.id,
        isoCode3: country.isoCode3,
        isoCode2: country.isoCode2,
        label: country.name,
        cashInCurrency: country.cashInCurrency,
      })),
    [destCountry]
  )

  const makeCalculationByInput = useCallback(
    (inputValue: number, mode: CalculateMode) => {
      const body = {
        payerId: formData.payers?.value,
        sendCountryCode: formData.srcCountry.isoCode3,
        sendCurrencyCode: formData.srcCountry.cashInCurrency,
        receiveCountryCode: formData.destCountry.isoCode3,
        receiveCurrencyCode: formData.destCountry.cashInCurrency,
        mode,
        amount: Number(inputValue),
      }

      // if()

      dispatch(calculationActionCreators.makeCalculation(body))
    },
    [
      dispatch,
      formData.destCountry.cashInCurrency,
      formData.destCountry.isoCode3,
      formData.payers?.value,
      formData.srcCountry.cashInCurrency,
      formData.srcCountry.isoCode3,
    ]
  )

  const debounceFunction = useMemo(
    () => debounce(makeCalculationByInput, 1000),
    [makeCalculationByInput]
  )

  useEffect(() => {
    onParentSubmit && onParentSubmit(formData as CalculationFormDataType)
  }, [formData, onParentSubmit])

  const setInitialFormData = useCallback(() => {
    const initialSrcCountryOption = sourceCountryOptions[0]
    const initialDestCountryOption = destCountryOptions[0]
    const initialServicesOption = servicesOptions[0]
    const initialPayersOption = payersOptions[0]

    setFormData(prev => ({
      ...prev,
      ...{
        destCountry: initialDestCountryOption,
        srcCountry: initialSrcCountryOption,
        services: initialServicesOption,
        payers: initialPayersOption,
      },
    }))

    isFirstInitializeRef.current = true
    // onParentSubmit(newState)
  }, [destCountryOptions, payersOptions, servicesOptions, sourceCountryOptions])

  useEffect(() => {
    if (sourceCountry.length > 0 && destCountry.length > 0 && !isFirstInitializeRef.current) {
      setInitialFormData()
    }
  }, [destCountry.length, setInitialFormData, sourceCountry.length])

  const setFormDataAfterCalc = useCallback(() => {
    const initialServicesOption = servicesOptions[0]
    const initialPayersOption = payersOptions[0]

    setFormData(prev => ({
      ...prev,
      ...{
        services: initialServicesOption,
        payers: initialPayersOption,
      },
    }))

    isFirstInitializeRef.current = true
    // onParentSubmit(newState)
  }, [payersOptions, servicesOptions])

  useEffect(() => {
    if (isFirstInitializeRef.current) {
      setFormDataAfterCalc()
    }
  }, [setFormDataAfterCalc])

  const setFormDataAfterCalc2 = useCallback(() => {
    const initialPayersOption = payersOptions[0]

    setFormData(prev => ({
      ...prev,
      ...{
        payers: initialPayersOption,
      },
    }))

    isFirstInitializeRef.current = true
    // onParentSubmit(newState)
  }, [payersOptions])

  useEffect(() => {
    if (isFirstInitializeRef.current) {
      setFormDataAfterCalc2()
    }
  }, [setFormDataAfterCalc2])

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      ...{
        destAmount: calculationInfo?.destination.amount
          ? calculationInfo?.destination.amount.toFixed(2)
          : '0',
        srcAmount: calculationInfo?.source.amount ? calculationInfo?.source.amount.toFixed(2) : '0',
      },
    }))
  }, [calculationInfo?.destination.amount, calculationInfo?.source.amount, setFormData])

  const onChange = useCallback(
    (value: any, name: string, mode: CalculateMode) => {
      const modifiedFormData = {
        ...formData,
        ...{ [name]: value, mode },
      }

      value && debounceFunction(value, mode)
      setFormData(modifiedFormData)
    },
    [debounceFunction, formData]
  )

  const onCustomSelectorChange = useCallback(
    (value: CommonOption, name: string) => {
      const modifiedFormData = {
        ...formData,
        ...{ [name]: value },
      }
      let body = {
        payerId: formData.payers.value,
        sendCountryCode: formData.srcCountry.isoCode3,
        sendCurrencyCode: formData.srcCountry.cashInCurrency,
        receiveCountryCode: formData.destCountry.isoCode3,
        receiveCurrencyCode: formData.destCountry.cashInCurrency,
        mode: formData.mode,
        amount:
          formData.mode === 'SOURCE_AMOUNT'
            ? Number(formData.srcAmount)
            : Number(formData.destAmount),
      }

      if (name === 'services') {
        dispatch(
          calculationActionCreators.makeCalculationDueToReceiveMethod({
            serviceId: value.value,
            body,
          })
        )
      }

      if (name === 'payers') {
        body = { ...body, payerId: Number(value.value) }
        dispatch(calculationActionCreators.makeCalculation(body))
      }

      setFormData(modifiedFormData)
    },
    [dispatch, formData]
  )

  const onCountrySelectorChange = useCallback(
    (value: CountryOption, name: string) => {
      let modifiedFormData = {
        ...formData,
        ...{ [name]: value },
      }

      let body = {
        payerId: formData.payers?.value,
        sendCountryCode: formData.srcCountry.isoCode3,
        sendCurrencyCode: formData.srcCountry.cashInCurrency,
        receiveCountryCode: formData.destCountry.isoCode3,
        receiveCurrencyCode: formData.destCountry.cashInCurrency,
        mode: formData.mode,
        amount:
          formData.mode === 'SOURCE_AMOUNT'
            ? Number(formData.srcAmount)
            : Number(formData.destAmount),
      }

      if (name === 'srcCountry') {
        body = {
          ...body,
          sendCountryCode: value.isoCode3,
          sendCurrencyCode: value.cashInCurrency,
        }

        dispatch(calculationActionCreators.makeCalculation(body))
      }

      if (name === 'destCountry') {
        body = {
          ...body,
          receiveCountryCode: value.isoCode3,
          receiveCurrencyCode: value.cashInCurrency,
        }

        dispatch(calculationActionCreators.makeCalculationDueToDestCountry(body))
          .unwrap()
          .then(() => {
            modifiedFormData = {
              ...modifiedFormData,
              ...{ payers: undefined, services: undefined },
            }
          })
      }

      // dispatch(calculationActionCreators.makeCalculation(body))

      setFormData(modifiedFormData)
    },
    [dispatch, formData]
  )

  const onContinueClick = useCallback((event: SyntheticEvent) => {
    event.preventDefault()

    // onContinueClick(formData)
  }, [])
  return (
    <>
      {/* {sourceCountry.length > 0 && destCountry.length > 0 && ( */}
      <form id="calculation-form" className="d-flex flex-col gap-24">
        <InputWithCountrySelector
          label="You send"
          inputName="srcAmount"
          inputValue={formData.srcAmount}
          selectorName="srcCountry"
          selectorValue={formData.srcCountry}
          onInputChange={onChange}
          mode="SOURCE_AMOUNT"
          onSelectorChange={onCountrySelectorChange}
          options={sourceCountryOptions}
          isLoading={makeCalculationLoadState.isLoading}
        />
        <div className="exchange-rate">
          <p>
            {calculationInfo?.rate
              ? `1 ${calculationInfo.source.currency} = ${calculationInfo.rate} ${calculationInfo.destination.currency}`
              : '-'}
          </p>
        </div>
        <InputWithCountrySelector
          label="Recipient gets"
          inputName="destAmount"
          inputValue={formData.destAmount}
          selectorName="destCountry"
          selectorValue={formData.destCountry}
          onInputChange={onChange}
          mode="DESTINATION_AMOUNT"
          onSelectorChange={onCountrySelectorChange}
          options={destCountryOptions}
          isLoading={makeCalculationLoadState.isLoading}
        />
        <div>
          {calculationInfo?.errors.map(error => (
            <p className="form-control-error">{error.message}</p>
          ))}
        </div>

        <CustomSelector
          options={servicesOptions}
          value={formData.services}
          onChange={onCustomSelectorChange}
          name="services"
          styles={selectStyles}
          labelName="Receive method"
        />
        <CustomSelector
          options={payersOptions}
          value={formData.payers}
          onChange={onCustomSelectorChange}
          name="payers"
          styles={selectStyles}
          labelName={`${formData.services?.label ? formData.services.label : ''} partner`}
        />
        <dl className="definition-list">
          <dt>Fee: </dt>
          <dd>
            {calculationInfo?.fee.amount
              ? `${calculationInfo?.fee.amount.toFixed(2)} ${calculationInfo?.fee.currency}`
              : '-'}
          </dd>

          <dt>Transfer time: </dt>
          <dd className="d-flex align-items-center gap-4 justify-content-end">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8.66667 1.3335L2 9.3335H8L7.33333 14.6668L14 6.66683H8L8.66667 1.3335Z"
                stroke="var(--Primary-600)"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            few minutes
          </dd>

          <dt>Total to pay: </dt>
          <dd>
            {calculationInfo?.totalAmount
              ? `${calculationInfo?.totalAmount.toFixed(2)} ${calculationInfo?.source.currency}`
              : '-'}
          </dd>
        </dl>

        {isShownSubmitBtn && (
          <div className="form-actions d-flex flex-col gap-8">
            <button
              form="calculation-form"
              type="submit"
              onClick={onContinueClick}
              className="btn btn-primary w-100"
              disabled={makeCalculationLoadState.isLoading}
            >
              Continue
            </button>
            <button type="button" className="btn btn-tertiary w-100">
              Cancel
            </button>
          </div>
        )}
      </form>
      {/* )} */}
    </>
  )
}

export default CalculationForm
