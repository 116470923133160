import { CommonOption } from '@customTypes/calculation'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import Select, { OnChangeValue, SingleValue } from 'react-select'

type SelectorProps<T extends FieldValues> = UseControllerProps<T> & {
  options: Array<CommonOption>
  styles: any
  labelName?: string
}

const FormSelector = <T extends FieldValues>({
  options,
  styles,
  labelName,
  name,
  control,
}: SelectorProps<T>) => {
  const {
    field: { onChange, value },
    fieldState: { error: inputError },
  } = useController({
    name,
    control,
  })

  const onSelectorChange = useCallback(
    (value: SingleValue<CommonOption>) => {
      onChange(value?.value)
    },
    [onChange]
  )

  return (
    <div className="form-group">
      <label className="form-control-label" htmlFor={name}>
        {labelName}
      </label>
      <div className="form-control-select">
        <Select
          id={name}
          isSearchable={false}
          styles={styles}
          isMulti={false}
          options={options}
          // value={selectValue}
          value={options.find(option => option.value === value)}
          onChange={onSelectorChange}
        />
      </div>
      <p className="form-control-error">{inputError?.message}</p>
    </div>
  )
}

export default FormSelector
