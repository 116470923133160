import { CustomErrorResponse } from '@customTypes/common'
import { createSlice } from '@reduxjs/toolkit'

type GlobalErrorModalState = {
  isModalOpen: boolean
  error: CustomErrorResponse | null
}

const initialState: GlobalErrorModalState = {
  isModalOpen: false,
  error: null,

}

export const globalErrorModalSlice = createSlice({
  name: 'globalErrorModal',
  initialState,
  reducers: {
    openErrorGlobalModal: (state, action) => {
      state.isModalOpen = true
      state.error = action.payload
    },
    closeErrorGlobalModal: state => {
      state.isModalOpen = false
      state.error = null
    },
  },
})

export const { openErrorGlobalModal, closeErrorGlobalModal } = globalErrorModalSlice.actions

export default globalErrorModalSlice.reducer
