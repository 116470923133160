export function combineUrl(...parts: string[]): string {
  return parts.reduce((url, part) => {
    if (!url.endsWith('/') && url.length > 0) {
      url += '/'
    }
    url += part
    return url
  }, '')
}

// export const trimObjectFields = (object: Record<string, string>) => {
//   return Object.keys(object).reduce((acc, key) => {
//     acc[key] = typeof object[key] == 'string' ? object[key].trim() : object[key]
//     return acc
//   }, {} as Record<string, string>)
// }
