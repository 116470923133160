import { PhoneNumberUtil } from 'google-libphonenumber'

const trimObjectFields = (object: Record<string, string>) => {
  return Object.keys(object).reduce((acc, key) => {
    acc[key] = typeof object[key] == 'string' ? object[key].trim() : object[key]

    return acc
  }, {} as Record<string, string>)
}

const checkPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) {
    return false
  }

  const phoneUtil = PhoneNumberUtil.getInstance()

  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phoneNumber))
  } catch (error) {
    return false
  }
}

const commonUtils = {
  checkPhoneNumber,
  trimObjectFields
}

export default commonUtils