import { calculationServices } from "@api/services/calculationServices"
import { MakeCalculationDueToReceiveMethodRequestArg, MakeCalculationRequestBody, MakeCalculationResponseBody, Payer } from "@customTypes/calculation"
import { CustomErrorResponse } from "@customTypes/common"
import { createAsyncThunk } from "@reduxjs/toolkit"
import calculationUtils from "@utils/calculation"

const getCountriesForCalculation = createAsyncThunk(
  'calculation/getCountriesForCalculation',
  async (_, { rejectWithValue }) => {
    try {

      const res = await calculationServices.getCountriesForCalculation()

      return res
    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  }
)

const makeInitialCalculation = createAsyncThunk(
  'calculation/makeInitialCalculation',
  async (_, { rejectWithValue }) => {
    try {
      const countries = await calculationServices.getCountriesForCalculation()
      const services = await calculationServices.getServices(countries.destination[0].isoCode3)
      // const services = []

      let payers: Array<Payer>
      let calculationResponse: MakeCalculationResponseBody | null

      if (services.length > 0) {
        const requestQuery = {
          receiveCountryCode: countries.destination[0].isoCode3,
          receiveCurrencyCode: countries.destination[0].cashInCurrency,
          serviceId: services[0].id
        }

        payers = await calculationServices.getPayers(requestQuery)
      } else {
        payers = []
      }

      // if(payers.length > 0)


      const requestBody = {
        payerId: payers[0]?.id,
        sendCountryCode: countries.source[0].isoCode3,
        sendCurrencyCode: countries.source[0].cashInCurrency,
        receiveCountryCode: countries.destination[0].isoCode3,
        receiveCurrencyCode: countries.destination[0].cashInCurrency,
        mode: 'SOURCE_AMOUNT',
        amount: 100,
      }

      if (calculationUtils.checkForUnfilledFields(requestBody)) {
        calculationResponse = await calculationServices.makeCalculation(requestBody)
      } else {
        calculationResponse = null
      }



      return { countries, services, payers, calculationResponse }

    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  })

const makeCalculation = createAsyncThunk(
  'calculation/makeCalculation',
  async (body: MakeCalculationRequestBody, { rejectWithValue }) => {
    try {
      // const requestBody = {
      //   payerId: payers[0].id,
      //   sendCountryCode: countries.source[0].isoCode3,
      //   sendCurrencyCode: countries.source[0].cashInCurrency,
      //   receiveCountryCode: countries.destination[0].isoCode3,
      //   receiveCurrencyCode: countries.destination[0].cashInCurrency,
      //   mode: 'SOURCE_AMOUNT',
      //   amount: 100,
      // }

      const calculationResponse = await calculationServices.makeCalculation(body)

      return calculationResponse

    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  })

const makeCalculationDueToDestCountry = createAsyncThunk(
  'calculation/makeCalculationDueToDestCountry',
  async (body: MakeCalculationRequestBody, { rejectWithValue }) => {
    try {
      const services = await calculationServices.getServices(body.receiveCountryCode)
      // const services = []

      let payers: Array<Payer>
      let calculationResponse: MakeCalculationResponseBody | null

      if (services.length > 0) {
        const requestQuery = {
          receiveCountryCode: body.receiveCountryCode,
          receiveCurrencyCode: body.receiveCurrencyCode,
          serviceId: services[0].id
        }

        payers = await calculationServices.getPayers(requestQuery)
      } else {
        payers = []
      }

      // if(payers.length > 0)


      const requestBody = {
        payerId: payers[0]?.id,
        sendCountryCode: body.sendCountryCode,
        sendCurrencyCode: body.sendCurrencyCode,
        receiveCountryCode: body.receiveCountryCode,
        receiveCurrencyCode: body.receiveCurrencyCode,
        mode: body.mode,
        amount: body.amount,
      }

      if (calculationUtils.checkForUnfilledFields(requestBody)) {
        calculationResponse = await calculationServices.makeCalculation(requestBody)
      } else {
        calculationResponse = null
      }



      return { services, payers, calculationResponse }

    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  })

const makeCalculationDueToReceiveMethod = createAsyncThunk(
  'calculation/makeCalculationDueToReceiveMethod',
  async (arg: MakeCalculationDueToReceiveMethodRequestArg, { rejectWithValue }) => {
    const { body, serviceId } = arg
    try {
      let calculationResponse: MakeCalculationResponseBody | null

      const requestQuery = {
        receiveCountryCode: body.receiveCountryCode,
        receiveCurrencyCode: body.receiveCurrencyCode,
        serviceId: serviceId
      }

      const payers = await calculationServices.getPayers(requestQuery)

      const requestBody = {
        ...body,
        payerId: payers[0]?.id,

      }

      if (calculationUtils.checkForUnfilledFields(requestBody)) {
        calculationResponse = await calculationServices.makeCalculation(requestBody)
      } else {
        calculationResponse = null
      }


      return { payers, calculationResponse }

    } catch (e) {
      const err = e as CustomErrorResponse

      return rejectWithValue('error')
    }
  })






export const calculationActionCreators = {
  getCountriesForCalculation,
  makeInitialCalculation,
  makeCalculation,
  makeCalculationDueToReceiveMethod,
  makeCalculationDueToDestCountry,
}