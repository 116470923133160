import { CommonOption } from '@customTypes/calculation'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import Select, { OnChangeValue, SingleValue } from 'react-select'

type SelectorProps = {
  options: Array<CommonOption>
  // onParentChange: (value: WithdrawalOption) => void
  onChange: (value: any, name: string) => void
  styles: any
  // parentSelectedValue: WithdrawalOption | undefined
  labelName?: string
  value: CommonOption
  name: string
}

const CustomSelector = ({ options, styles, labelName, onChange, name, value }: SelectorProps) => {
  const onSelectorChange = useCallback(
    (value: SingleValue<CommonOption>) => {
      onChange(value, name)
    },
    [name, onChange]
  )

  return (
    <div className="form-row">
      {labelName && <label htmlFor="selector">{labelName}</label>}
      <Select
        // inputId="aria-example-input"
        isSearchable={false}
        styles={styles}
        isMulti={false}
        options={options}
        // value={selectValue}
        value={options.find(option => option.value === value?.value)}
        onChange={onSelectorChange}
      />
    </div>
  )
}

export default CustomSelector
