import { ChangeEvent, useCallback } from 'react'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'

type FormInputProps<T extends FieldValues> = UseControllerProps<T> & {
  placeholder: string
  type: string
  label: string
  maxLength: number
  autoComplete?: 'on' | 'off'
  disabled?: boolean
}

const FormInput = <T extends FieldValues>({
  name,
  control,
  placeholder,
  type,
  label,
  maxLength,
  autoComplete = 'off',
  disabled,
}: FormInputProps<T>) => {
  const {
    field: { onChange, value },
    fieldState: { error: inputError },
  } = useController({
    name,
    control,
  })

  return (
    <div className={`form-group${disabled ? ' cursor-not-allowed' : ''}`}>
      <label className="form-control-label" htmlFor={name}>
        {label}
      </label>
      <input
        id={name}
        className="form-control"
        onChange={onChange}
        value={typeof value === 'undefined' ? '' : value}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        maxLength={maxLength}
        {...(disabled && { disabled })}
      />
      <p className="form-control-error">{inputError?.message}</p>
    </div>
  )
}

export default FormInput
