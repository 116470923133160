import { calculationActionCreators } from '@actionCreators/calculationActionCreators'
import { calculationServices } from '@api/services/calculationServices'
import CalculationForm from '@components/calculation/CalculationForm'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { useEffect } from 'react'
//import secondSectionImg from '@images/mobile-phone-sec-2.webp'
import secondSectionImg from '@images/Iphone_14.svg'

const Home = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(calculationActionCreators.makeInitialCalculation())
  }, [dispatch])
  return (
    <div>
      <section className="form-section">
        <div className="container container-lg d-flex align-items-center justify-content-center">
          <div className="form-section--content">
            <p className="mb-24 f-monrope-lg">Send in under 90 seconds*</p>
            <h1>Pay 3 Billion+ bank accounts & wallets worldwide</h1>
            <p className="f-monrope-md">
              Effortlessly pay people and businesses in over 130 countries
            </p>
          </div>
          <div className="calculation-form">
            <CalculationForm isShownSubmitBtn={true} />
          </div>
        </div>
      </section>

      <section className="two-col">
        <div className="container container-lg d-flex align-items-center justify-content-center">
          <div className="two-col--thumbnail">
            <img src={secondSectionImg} alt="mobile phone" width={766} height={856} />
          </div>
          <div className="two-col--content">
            <h2 className="">Need it over there fast? Done.</h2>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home
