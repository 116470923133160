import Icon from '@components/Icon'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { closeErrorGlobalModal } from '@sliceActionCreators/globalErrorModalSlice'
import { Dispatch, SetStateAction, useCallback } from 'react'
import ReactModal from 'react-modal'

// type ErrorModalProps = {
//   setIsOpenModal: Dispatch<SetStateAction<boolean>>
//   isOpenModal: boolean
// }

const ErrorModal = () => {
  const { isModalOpen, error } = useAppSelector(state => state.globalErrorModal)
  const dispatch = useAppDispatch()

  const onClose = useCallback(() => {
    dispatch(closeErrorGlobalModal())
  }, [dispatch])

  return (
    <ReactModal
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      className=""
      overlayClassName="dialog-overlay"
      isOpen={isModalOpen}
    >
      <>
        <div className="dialog medium">
          <button className="dialog-close" onClick={onClose}>
            <Icon name="close" size={24} />
          </button>
          <div className="dialog-title error-title">Error</div>
          <div className="dialog-body">
            {error?.errorCode && <span>{error?.errorCode}</span>}
            <span>{error?.message}</span>
          </div>
          <div className="dialog-footer button-group gapless">
            {/* <button type="button" className="btn btn-tertiary" onClick={onClose}>
              Cancel
            </button> */}

            <button type="button" className="btn btn-primary confirm-button" onClick={onClose}>
              Ok
            </button>
          </div>
        </div>
      </>
    </ReactModal>
  )
}

export default ErrorModal
