import localStorageData from '@utils/localStorage'
import React from 'react'
import { Navigate, Outlet } from 'react-router'

const ProtectedSignInRoute = () => {
  const token = localStorageData.getData('authData')

  if (token) {
    return <Navigate to="/" />
  } else {
    return <Outlet />
  }
}

export default ProtectedSignInRoute
