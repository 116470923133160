import Icon from '@components/Icon'
import dateUtils from '@utils/date'
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import MaskedInput from 'react-text-mask'

const shouldItUseShowPicker = () => {
  const toMatch = [
    /Firefox/i,
    // /iPhone/i,
    // /iPad/i,
  ]

  return toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem))
}

const isShowPickerUsed = shouldItUseShowPicker()

type FormDateInputProps<T extends FieldValues> = UseControllerProps<T> & {
  // placeholder: string
  // type: string
  label: string
  // maxLength: number
  autoComplete?: 'on' | 'off'
  disabled?: boolean
}

const FormDateInput = <T extends FieldValues>({
  name,
  control,
  label,
  disabled,
}: FormDateInputProps<T>) => {
  const {
    field: { onChange, value },
    fieldState: { error: inputError },
  } = useController({
    name,
    control,
  })

  const [nativeDateInputValue, setNativeDateInputValue] = useState('')
  const [uiDateInputValue, setUiDateInputValue] = useState('')

  const nativeDateInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (value) {
      const dateForUiDateInput = value.split('-').reverse().join('-')

      setUiDateInputValue(dateForUiDateInput)

      if (dateUtils.checkForDateExistence(value)) {
        setNativeDateInputValue(value)
      } else {
        setNativeDateInputValue('2000-01-01')
      }
    } else {
      setNativeDateInputValue('2000-01-01')
    }
  }, [value])

  const currentClassName = useMemo(() => {
    let className = 'form-date-input-ui'

    if (inputError) {
      className = className + ' error'
    }
    return className
  }, [inputError])

  const onClick = () => {
    // @ts-ignore
    nativeDateInputRef.current!.showPicker()
  }

  const onChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      const dateForNativeInput = value.split('-').reverse().join('-')

      if (dateUtils.checkForDateExistence(dateForNativeInput)) {
        setNativeDateInputValue(dateForNativeInput)
      }

      onChange(dateForNativeInput)
      setUiDateInputValue(value)
    },
    [onChange]
  )

  const onChangeNativeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      const dateForInput = value.split('-').reverse().join('-')

      onChange(value)
      setNativeDateInputValue(value)
      setUiDateInputValue(dateForInput)
    },
    [onChange]
  )


  return (
    <div
      // className="form-input"
      className={`form-group${disabled ? ' cursor-not-allowed' : ''}`}
      // hidden={hidden}
    >
      {label && (
        <label className="form-control-label"
        //  onClick={onClick}
         >
          {label}
        </label>
      )}
      <div
        className={disabled ? 'form-input-block cursor-notAllowed disabled' : 'form-input-block'}
      >
        <MaskedInput
          className="form-control"
          onChange={onChangeInput}
          value={uiDateInputValue}
          mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          guide={false}
          type="tel"
          inputMode="numeric"
          placeholder="dd-mm-yyyy"
          autoComplete="off"
        />
        {/* <NumberFormat
        allowNegative={false}
        onChange={onChange}
        value={uiDateInputValue}
        format={'##-##-####'}
        type="tel"
        inputMode="numeric"
        disabled={disabled}
        placeholder={placeholder}
        autoComplete="off"
        className={currentClassName}
      /> */}
        {/* <span className="form-date-input-icon" 
        {...(isShowPickerUsed && { onClick })}
        onClick={onClick}
        >
          <Icon name="calendar" size={24} />
        </span> */}
        {/* <input
          ref={nativeDateInputRef}
          type="date"
          disabled={disabled}
          value={nativeDateInputValue}
          onChange={onChangeNativeInput}
          className={`form-date-input${isShowPickerUsed ? '' : ' show-date-input'}`}
        /> */}
      </div>
      <p className="form-control-error">{inputError?.message}</p>
    </div>
   
  )
}

export default FormDateInput
