import { authorizedRequest } from "@api/authorizeRequest"
import { User } from "@customTypes/user"

const getProfile = () => {
  const path = ['profile']

  return authorizedRequest<null, User>('v1', path)
}

const userServices = {
  getProfile,
}

export default userServices