import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import SocialMediaButton from './SocialMediaButton'
import { useAppDispatch } from '@hooks/reduxHooks'
import authActionCreators from '@actionCreators/authActionCreators'
import { SocialMediaConfig } from '@customTypes/auth'
import { SocialMediaIcons } from '@helpers/socialMediaIcons'

const socialMediaList = [
  {
    id: 'google',
    svg: <SocialMediaIcons.Google />,
    text: 'Continue with Google',
  },
] as Array<SocialMediaConfig>

const LoginViaSocialMedia = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const onGoogleClick = useCallback(async () => {
    dispatch(authActionCreators.signInFirebaseWithGoogle())
      .unwrap()
      .then(() => {
        navigate('/')
      })
  }, [dispatch, navigate])

  const onClickForButtons = useCallback(
    (id: 'google' | 'tikTok') => {
      switch (id) {
        case 'google': {
          return onGoogleClick()
        }
      }
    },
    [onGoogleClick]
  )

  return (
    <>
      {socialMediaList.map(socialMediaItem => (
        <SocialMediaButton
          key={socialMediaItem.id}
          id={socialMediaItem.id}
          text={socialMediaItem.text}
          svg={socialMediaItem.svg}
          onParentClick={onClickForButtons}
        />
      ))}
    </>
  )
}

export default LoginViaSocialMedia
