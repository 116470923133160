import { ReactNode, useCallback, useMemo, useState } from 'react'
import { jsx } from '@emotion/react'
// import Button from '@atlaskit/button'
import * as Flags from 'country-flag-icons/react/3x2'

import Select, { OptionProps, SingleValue, StylesConfig } from 'react-select'
import { defaultTheme, components } from 'react-select'
import { selectStyles } from './styleConfig'
import { CommonOption, CountryOption } from '@customTypes/calculation'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
// import { StateOption, stateOptions } from '../data'

const { colors } = defaultTheme

const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
}: {
  children?: ReactNode
  readonly isOpen: boolean
  readonly target: ReactNode
  readonly onClose: () => void
}) => (
  <div style={{ position: 'relative' }} className="form-group">
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
)

type FormCountrySelectorWithSearchProps<T extends FieldValues> = UseControllerProps<T> & {
  // selectorName: string
  // selectorValue: CommonOption
  options: Array<CommonOption>
  labelName: string
  // onParentChange: (value: any, name: string) => void
}

export const FormCountrySelectorWithSearch = <T extends FieldValues>({
  // selectorName,
  // selectorValue,
  options,
  // onParentChange,
  name,
  control,
  labelName,
}: FormCountrySelectorWithSearchProps<T>) => {
  const {
    field: { onChange, value },
    fieldState: { error: inputError },
  } = useController({
    name,
    control,
  })

  const [isOpen, setIsOpen] = useState(false)

  // const flagCode = selectorValue.isoCode2 as keyof typeof Flags

  // const FlagComponents = Flags[`${flagCode}`]

  const onSelectorChange = useCallback(
    (value: SingleValue<CommonOption>) => {
      // onParentChange(value, selectorName)
      onChange(value?.value)

      setIsOpen(prevState => !prevState)
    },
    [onChange]
  )

  const valueForShowing = useMemo(
    () => options.find(option => option.value === value),
    [options, value]
  )

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <>
          <label className="form-control-label" htmlFor={name}>
            {labelName}
          </label>
          <div className="form-control-select">
            <button
              type="button"
              onClick={() => setIsOpen(prev => !prev)}
              style={{
                width: '100%',
                padding: '8px 16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              // className="form-control-select"
            >
              {valueForShowing?.label ? (
                <>
                  {valueForShowing.label}
                  <ChevronDown />
                </>
              ) : (
                <>
                  Select...
                  <ChevronDown />
                </>
              )}
            </button>
          </div>

          <p className="form-control-error">{inputError?.message}</p>
        </>
      }
    >
      <Select
        // autoFocus
        backspaceRemovesValue={false}
        components={{
          DropdownIndicator,
          IndicatorSeparator: null,
          //  Option: CustomOption
        }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={onSelectorChange}
        options={options}
        placeholder="Search..."
        styles={selectStyles}
        tabSelectsValue={false}
        // value={value}
        value={options.find(option => option.value === value)}
      />
    </Dropdown>
  )
}

const Menu = (props: JSX.IntrinsicElements['div']) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)'
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
        top: '60px',
      }}
      {...props}
    />
  )
}
const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    className="test"
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
)

const Svg = (p: JSX.IntrinsicElements['svg']) => (
  <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
)
const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
)
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
)

export default FormCountrySelectorWithSearch
